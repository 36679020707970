import Competition from "model/Competition";
import {API_URL} from "common/const";
import {fetchApiClient} from "common/fetchApiClient";
import { AxiosError } from "axios";
import UnauthorizedError from "api/errors/UnauthorizedError";


const editCompetition = async (competition: Competition, token: string | undefined): Promise<void> => {
    const apiClient = fetchApiClient(token);

    await apiClient.post(`${API_URL}/competitions`, {
        competition: competition
    }).catch((error: AxiosError) => {
        console.log(`Error editing Competition(${competition})`, error);
        if (!!error.response && error.response.status === 403) {
            throw new UnauthorizedError();
        }
        throw error;
    });
}

export default editCompetition;
