import {API_URL} from "common/const";
import {fetchApiClient} from "common/fetchApiClient";
import {AxiosError} from "axios";
import UnauthorizedError from "api/errors/UnauthorizedError";
import User from "../model/User";


const signIn = async (user: User | undefined, token: string | undefined): Promise<User> => {
    const apiClient = fetchApiClient(token);

    return await apiClient.post<User>(`${API_URL}/auth/sign_in`, {
        user: user
    }).then(response => {
        return response.data;
    }).catch((error: AxiosError) => {
        console.log(`Error signing in for User(${user}) and Token(${token})`, error);
        if (!!error.response && error.response.status === 403) {
            throw new UnauthorizedError();
        }
        throw error;
    });
}

export default signIn;
