import SpaceBetween from "@awsui/components-react/space-between";
import React, { ReactNode, useState } from "react";
import NavigationBar from "components/NavigationBar";
import Container from "@awsui/components-react/container";
import Header from "@awsui/components-react/header";
import { FlashbarProps } from "@awsui/components-react/flashbar/interfaces";
import { useQuery } from "react-query";
import Rider from "model/Rider";
import getAllRiders from "api/getAllRiders";
import { useAuth } from "hooks/useAuth";
import Spinner from "@awsui/components-react/spinner";
import Form from "@awsui/components-react/form";
import Button from "@awsui/components-react/button";
import FormField from "@awsui/components-react/form-field";
import Select from "@awsui/components-react/select";
import EditedRider from "model/admin/EditedRider";
import SetRidersCountryButton from "pages/admin/pages/set_basqueness/components/SetRidersCountryButton";
import Box from "@awsui/components-react/box";
import Link from "@awsui/components-react/link";
import SubmitFlashbar from "components/SubmitFlashbar";


const SetBasquenessPage: React.FC = () => {

    const [{ user }] = useAuth();

    const [editedRiders, setEditedRiders] = useState<Map<number, EditedRider>>(new Map());
    const [buttonEnabled, setButtonEnabled] = useState(true);
    const [submitMessage, setSubmitMessage] = useState<FlashbarProps.MessageDefinition>();

    const {
        isLoading: allRidersIsLoading,
        data: allRiders,
        error: getAllRidersError
    } = useQuery<Rider[], Error>(`getAllRiders`, () => getAllRiders(user?.token), {
        onSuccess: (allRiders: Rider[]) => {
            setEditedRiders(new Map(allRiders
                .filter(rider => ["France", "Spain", "Basque Country"].includes(rider.country || ""))
                .sort((a, b) => ((a.country || "") > (b.country || "")) ? -1 : 1)
                .map(rider => [
                    rider.riderId || 0,
                    new EditedRider(rider.riderId || 0, rider.name, rider.country || "")
                ])));
        }
    });

    if (getAllRidersError) {
        setSubmitMessage({
            header: "Error when fetching all cyclists",
            type: "error",
            content: "Try reloading the page.",
            dismissible: false,
        });
    }

    const getAllRidersAsFormFields = (): ReactNode[] => {
        if (allRidersIsLoading || !allRiders || !editedRiders) {
            return [<Spinner />];
        }

        return [...editedRiders.values()].map(rider => (
            <FormField key={rider.riderId} label={<Header variant="h2">{rider.name}</Header>}>
                <Select
                    selectedOption={{
                        label: rider.country,
                        value: rider.country
                    }}
                    onChange={({ detail }) => (
                        setEditedRiders(new Map(editedRiders.set(rider.riderId, {
                            ...rider,
                            country: detail.selectedOption.value || ""
                        })))
                    )}
                    options={["France", "Spain", "Basque Country"].map((countryName: string) => ({
                        label: countryName,
                        value: countryName
                    }))}
                    selectedAriaLabel="Selected"
                />
            </FormField>
        ))
    }

    return (
        <SpaceBetween size="l">
            <NavigationBar />
            <Container
                header={
                    <Box variant={"span"}>
                        <Header variant="h1">Set basqueness</Header>
                        <Link variant={"info"} href={"/admin"}>Back</Link>
                    </Box>
                }
            >
                <SpaceBetween size={"m"}>
                    <form onSubmit={e => e.preventDefault()}>
                        <Form
                            actions={
                                <SpaceBetween direction="horizontal" size="xs">
                                    <Button formAction="none" variant="link" href="/admin">
                                        Cancelar
                                    </Button>
                                    <SetRidersCountryButton
                                        editedRiders={[...editedRiders.values()]}
                                        buttonEnabled={buttonEnabled}
                                        setButtonEnabled={setButtonEnabled}
                                        setSubmitMessage={setSubmitMessage}
                                    />
                                </SpaceBetween>
                            }
                        >
                            <SpaceBetween direction="vertical" size="l">
                                {submitMessage && (
                                    <SubmitFlashbar
                                        submitMessage={submitMessage}
                                        setSubmitMessage={setSubmitMessage}
                                    />
                                )}
                                {getAllRidersAsFormFields()}
                            </SpaceBetween>
                        </Form>
                    </form>
                </SpaceBetween>
            </Container>
        </SpaceBetween>
    )
};

export default SetBasquenessPage;
