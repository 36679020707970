import React from "react";
import Table, { TableProps } from "@awsui/components-react/table";
import Competition from "model/Competition";
import StatsItem from "model/StatsItem";
import getStats from "api/getStats";
import { useQuery } from "react-query";
import TextFilter from "@awsui/components-react/text-filter";
import CollectionPreferences from "@awsui/components-react/collection-preferences";
import useTable from "hooks/useTable";
import Pagination from "@awsui/components-react/pagination";
import CompetitionsHeader from "components/CompetitionsHeader";
import {useAuth} from "hooks/useAuth";
import Rider from "model/Rider";
import FlagIcon from "components/icons/FlagIcon";
import {formatNumber} from "common/formatNumber";
import RiderPopover from "components/RiderPopover";


interface StatsTableProps {
    competition: Competition;
}

const columnDefinitions: TableProps.ColumnDefinition<StatsItem>[] = [
    {
        id: "name",
        header: "Nombre",
        cell: (item: StatsItem) => <RiderPopover rider={item} />,
        sortingField: "name"
    },
    {
        id: "team",
        header: "Equipo",
        cell: (item: StatsItem) => item.team,
        sortingField: "team"
    },
    {
        id: "country",
        header: "País",
        cell: (item: Rider) => item.country && <FlagIcon country={item.country}/>,
        sortingField: "country"
    },
    {
        id: "points",
        header: "Puntos",
        cell: (item: StatsItem) => item.points,
        sortingField: "points"
    },
    {
        id: "value",
        header: "Valor",
        cell: (item: StatsItem) => `${item.value} mls.`,
        sortingField: "value"
    },
    {
        id: "efficiency",
        header: "Eficiencia",
        cell: (item: StatsItem) => formatNumber(item.efficiency, 2),
        sortingField: "efficiency"
    },
    {
        id: "ideal",
        header: "Equipo ideal",
        cell: (item: StatsItem) => item.ideal ? "Sí" : "No",
        sortingField: "ideal"
    },
    {
        id: "pickedPercentage",
        header: "Porcentaje de elección",
        cell: (item: StatsItem) => item.pickedPercentage ? `${formatNumber(item.pickedPercentage, 2)} %` : "-",
        sortingField: "pickedPercentage"
    },
];

const StatsTable: React.FC<StatsTableProps> = (props) => {

    const competition = props.competition;

    const [{user}] = useAuth();

    const {
        isLoading: statsIsLoading,
        data: stats,
        error: getStatsError
    } = useQuery<StatsItem[], Error>(`getStats-${competition.code}`, () => getStats(competition, user?.token));

    const {
        preferences,
        setPreferences,
        items,
        collectionProps,
        filterProps,
        paginationProps,
        collectionPreferencesProps
    } = useTable(stats || [], {
        emptyStateSubtitle: getStatsError as Error,
        emptyStateTitle: "No hay estadísticas."
    });


    return (
        <Table
            {...collectionProps}
            loadingText="Cargando las estadísticas"
            columnDefinitions={columnDefinitions}
            items={items}
            loading={statsIsLoading}
            wrapLines={false}
            header={<CompetitionsHeader competition={competition} />}
            pagination={
                <Pagination
                    {...paginationProps}
                    ariaLabels={{
                        nextPageLabel: "Siguiente página",
                        previousPageLabel: "Página anterior",
                        pageLabel: pageNumber => `Página ${pageNumber}`
                    }}
                />
            }
            filter={
                <TextFilter
                    {...filterProps}
                    filteringPlaceholder="Buscar por texto"
                />
            }
            preferences={
                <CollectionPreferences
                    {...collectionPreferencesProps}
                    preferences={preferences}
                    onConfirm={({ detail }) => setPreferences(detail)}
                />
            }
        />
    );
};

export default StatsTable;