import League from "model/League";

class User {
    name?: string;
    email?: string;
    photoUrl?: string;
    uid?: string;
    token?: string;
    method?: string;
    leagues?: League[];

    constructor(name: string, email: string, photoUrl: string, uid: string, token: string, method: string) {
        this.name = name;
        this.email = email;
        this.photoUrl = photoUrl;
        this.uid = uid;
        this.token = token;
        this.method = method;
    }
}

export default User;
