import React, { useState } from "react";
import { useCollection } from "@awsui/collection-hooks";
import Button from "@awsui/components-react/button";
import EmptyState from "components/EmptyState";
import { CollectionPreferencesProps } from "@awsui/components-react/collection-preferences/interfaces";


interface UseTableOptions {
    emptyStateSubtitle: Error;
    emptyStateTitle: string;
    pageSize?: number;
}

/* eslint-disable  @typescript-eslint/no-explicit-any */
const useTable = (allItems: Array<any>, options: UseTableOptions): any => {
    const pageSizePreference = {
        title: "Selecciona el tamaño de página",
        options: [
            { value: 10, label: "10 elementos" },
            { value: 20, label: "20 elementos" },
            { value: 50, label: "50 elementos" },
            { value: 200, label: "200 elementos" }
        ]
    };

    const { emptyStateTitle, emptyStateSubtitle, pageSize } = options;

    const collectionPreferencesProps = {
        pageSizePreference,
        cancelLabel: "Cancelar",
        confirmLabel: "Confirmar",
        title: "Preferencias"
    };

    const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences<any>>({
        pageSize: pageSize || 10
    });
    const {
        items,
        actions,
        filteredItemsCount,
        collectionProps,
        filterProps,
        paginationProps
    } = useCollection(allItems, {
        filtering: {
            empty: (
                <EmptyState
                    title={emptyStateTitle}
                    subtitle={emptyStateSubtitle?.message}
                />
            ),
            noMatch: (
                <EmptyState
                    title={emptyStateTitle}
                    subtitle="No hemos podido encontrar nada"
                    action={
                        <Button onClick={() => actions.setFiltering("")}>
                            Limpiar filtro
                        </Button>
                    }
                />
            )
        },
        pagination: {
            pageSize: preferences.pageSize
        },
        sorting: {},
        selection: {
            keepSelection: true
        },
    });
    const { selectedItems } = collectionProps;

    return {
        preferences,
        setPreferences,
        items,
        actions,
        filteredItemsCount,
        collectionProps,
        filterProps,
        paginationProps,
        selectedItems,
        collectionPreferencesProps
    };
};

export default useTable;