import React, { useState } from "react";
import SpaceBetween from "@awsui/components-react/space-between";
import NavigationBar from "components/NavigationBar";
import Container from "@awsui/components-react/container";
import StandingsTable from "pages/standings/components/StandingsTable";
import Competition from "model/Competition";
import CompetitionsSelect from "components/CompetitionsSelect";
import AllResultsComponent from "pages/standings/components/AllResultsComponent";
import CompetitionsHeader from "components/CompetitionsHeader";


const StandingsPage: React.FC = () => {

    const [selectedCompetition, setSelectedCompetition] = useState<Competition>();

    return (
        <SpaceBetween size={"l"}>
            <NavigationBar />
            <Container
                variant={"default"}
                header={<CompetitionsHeader competition={selectedCompetition} />}
            >
                <SpaceBetween size={"l"}>
                    <CompetitionsSelect
                        selectedCompetition={selectedCompetition}
                        setSelectedCompetition={setSelectedCompetition}
                        disabledStates={[]}
                    />
                    {selectedCompetition && (
                        <StandingsTable
                            competition={selectedCompetition}
                        />
                    )}
                    {selectedCompetition && (
                        <AllResultsComponent competition={selectedCompetition} />
                    )}
                </SpaceBetween>
            </Container>

        </SpaceBetween>
    )
};

export default StandingsPage;
