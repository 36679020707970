import Competition from "model/Competition";
import League from "model/League";
import {fetchApiClient} from "common/fetchApiClient";
import {API_URL} from "common/const";
import UserAnswer from "model/UserAnswer";
import { AxiosError } from "axios";
import UnauthorizedError from "api/errors/UnauthorizedError";


const saveAnswers = async (competition: Competition, league: League | undefined, selectedAnswers: UserAnswer[], token: string | undefined): Promise<void> => {
    const apiClient = fetchApiClient(token);

    await apiClient.put(`${API_URL}/answers/${competition.code}/${league?.leagueId}`, {
        answers: selectedAnswers
    }).catch((error: AxiosError) => {
        console.log(`Error saving answers for Competition(${competition.code}) and League(${league?.name})`, selectedAnswers, error);
        if (!!error.response && error.response.status === 403) {
            throw new UnauthorizedError();
        }
        throw error;
    });
}

export default saveAnswers;
