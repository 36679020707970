import React, {Dispatch, SetStateAction} from "react";
import Button from "@awsui/components-react/button";
import {useMutation} from "react-query";
import {FlashbarProps} from "@awsui/components-react/flashbar/interfaces";
import {useAuth} from "hooks/useAuth";
import EditedRider from "model/admin/EditedRider";
import setRidersCountry from "api/setRidersCountry";


interface SetRidersCountryButtonProps {
    editedRiders: EditedRider[];
    buttonEnabled: boolean;
    setButtonEnabled: Dispatch<SetStateAction<boolean>>;
    setSubmitMessage: Dispatch<SetStateAction<FlashbarProps.MessageDefinition | undefined>>;
}

const SetRidersCountryButton: React.FC<SetRidersCountryButtonProps> = (props) => {

    const [{user}] = useAuth();

    const {
        mutate: callSetRiderCountry,
        isLoading: setRidersCountryIsLoading
    } = useMutation((riders: EditedRider[]) => setRidersCountry(riders, user?.token), {
        onSuccess: () => {
            props.setSubmitMessage({
                header: "Rider countries selected correctly",
                type: "success",
                content: "",
            })
        },
        onError: () => {
            props.setSubmitMessage({
                header: "Error when setting riders' country",
                type: "error",
                content: "Try to refresh the page.",
            })
        },
        onSettled: () => {
            props.setButtonEnabled(false);
        }
    });

    return (
        <Button
            disabled={!props.buttonEnabled || (props.editedRiders.length === 0)}
            iconAlign="left"
            loading={setRidersCountryIsLoading}
            variant="normal"
            onClick={() => callSetRiderCountry(props.editedRiders)}
        >
            Submit
        </Button>
    );
};

export default SetRidersCountryButton;
