import Competition from "model/Competition";
import {API_URL} from "common/const";
import {fetchApiClient} from "common/fetchApiClient";
import UnauthorizedError from "api/errors/UnauthorizedError";
import { AxiosError } from "axios";


const getCompetitions = async (token: string | undefined): Promise<Competition[]> => {
    const apiClient = fetchApiClient(token);

    return await apiClient.get<Competition[]>(`${API_URL}/competitions`)
        .then(response => {
            return response.data;
        }).catch((error: AxiosError) => {
            console.log("Error fetching competitions", error);
            if (!!error.response && error.response.status === 403) {
                throw new UnauthorizedError();
            }
            throw error;
        });
}

export default getCompetitions;
