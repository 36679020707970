import SpaceBetween from "@awsui/components-react/space-between";
import React, {useState} from "react";
import NavigationBar from "components/NavigationBar";
import Container from "@awsui/components-react/container";
import Header from "@awsui/components-react/header";
import Textarea from "@awsui/components-react/textarea";
import {FlashbarProps} from "@awsui/components-react/flashbar/interfaces";
import Box from "@awsui/components-react/box";
import RiderAndSynonyms from "model/RiderAndSynonyms";
import Select from "@awsui/components-react/select";
import RiderSynonym from "model/RiderSynonym";
import GetRiderSynonymsButton from "pages/admin/pages/add_rider_synonyms/components/GetRiderSynonymsButton";
import AddRiderSynonymsButton from "pages/admin/pages/add_rider_synonyms/components/AddRiderSynonymsButton";
import Link from "@awsui/components-react/link";
import SubmitFlashbar from "components/SubmitFlashbar";


const AddRiderSynonymsPage: React.FC = () => {

    const [ridersText, setRidersText] = useState("");
    const [getSynonymsButtonEnabled, setGetSynonymsButtonEnabled] = useState(true);
    const [submitMessage, setSubmitMessage] = useState<FlashbarProps.MessageDefinition>();
    const [riderSynonyms, setRiderSynonyms] = useState<RiderAndSynonyms[]>();
    const [selectedSynonyms, setSelectedSynonyms] = useState<Map<string, RiderSynonym>>(new Map());

    const changeSelectedSynonyms = (riderSynonym: RiderAndSynonyms, selectedOption: string | undefined): void => {
        const selectedSynonym = riderSynonym.synonyms
            .filter(synonym => synonym.synonym === selectedOption)
            .find(s => s);
        if (!!selectedSynonym) {
            console.log("SelectedSynonym: ", selectedSynonym);
            console.log("SelectedSynonyms: ", selectedSynonyms);
            console.log("SelectedSynonyms.get: ", selectedSynonyms.get(riderSynonym.riderName));
            const newMap = selectedSynonyms.set(riderSynonym.riderName, selectedSynonym);
            setSelectedSynonyms(newMap);
        }
    }

    return (
        <SpaceBetween size="l">
            <NavigationBar/>
            <Container
                header={
                    <Box variant={"span"}>
                        <Header variant="h1">Add rider synonyms</Header>
                        <Link variant={"info"} href={"/admin"}>Back</Link>
                    </Box>
                }
            >
                {submitMessage && (
                    <SubmitFlashbar
                        submitMessage={submitMessage}
                        setSubmitMessage={setSubmitMessage}
                    />
                )}
                <Container header={<Header variant="h3">Initial riders</Header>}>
                    <SpaceBetween size={"m"}>
                        <Textarea
                            onChange={({detail}) => {
                                setRidersText(detail.value);
                                setGetSynonymsButtonEnabled(true);
                            }}
                            value={ridersText}
                            disableBrowserAutocorrect
                            disableBrowserSpellcheck
                            rows={15}
                            placeholder="Add riders"
                        />
                        <GetRiderSynonymsButton
                            ridersText={ridersText}
                            setRidersText={setRidersText}
                            buttonEnabled={getSynonymsButtonEnabled}
                            setButtonEnabled={setGetSynonymsButtonEnabled}
                            setSubmitMessage={setSubmitMessage}
                            setRiderSynonyms={setRiderSynonyms}
                        />
                    </SpaceBetween>
                </Container>

                {(riderSynonyms != undefined && riderSynonyms.length > 0) ? (
                    <Container header={<Header variant="h3">Synonyms</Header>}>
                        <SpaceBetween size={"m"}>
                            {riderSynonyms.map(riderSynonym => {
                                return (
                                    <Box key={`${riderSynonym.riderName}-box`}>
                                        <Header variant={"h3"}>{riderSynonym.riderName}</Header>
                                        <Select
                                            name={riderSynonym.riderName}
                                            selectedOption={{
                                                label: selectedSynonyms.get(riderSynonym.riderName)?.synonym,
                                                value: selectedSynonyms.get(riderSynonym.riderName)?.synonym
                                            }}
                                            onChange={({detail}) =>
                                                changeSelectedSynonyms(riderSynonym, detail.selectedOption.value)}
                                            options={riderSynonym.synonyms.map((synonym, index) => ({
                                                label: `${index} - ${synonym.synonym} (${synonym.distance})`,
                                                value: synonym.synonym
                                            }))}
                                            selectedAriaLabel="Selected"
                                            key={`${riderSynonym.riderName}-select`}
                                        />
                                    </Box>
                                );
                            })}
                            <Box float="right">
                                <AddRiderSynonymsButton
                                    synonyms={selectedSynonyms}
                                    setSubmitMessage={setSubmitMessage}
                                />
                            </Box>
                        </SpaceBetween>
                    </Container>
                ) : null}
            </Container>
        </SpaceBetween>
    )
};

export default AddRiderSynonymsPage;
