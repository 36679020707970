import React, { useState } from "react";
import SpaceBetween from "@awsui/components-react/space-between";
import NavigationBar from "components/NavigationBar";
import Container from "@awsui/components-react/container";
import Header from "@awsui/components-react/header";
import Competition from "model/Competition";
import CompetitionsSelect from "components/CompetitionsSelect";
import { CompetitionState } from "model/CompetitionState";
import QuestionsForm from "pages/questions/components/QuestionsForm";
import CompetitionsHeader from "components/CompetitionsHeader";
import NoOpenCompetitionsFlashbar from "components/NoOpenCompetitionsFlashbar";


const QuestionsPage: React.FC = () => {

    const [selectedCompetition, setSelectedCompetition] = useState<Competition>();

    return (
        <SpaceBetween size={"l"}>
            <NavigationBar />
            <Container
                variant={"default"}
                header={
                    <Header variant={"h2"}>
                        Preguntas
                    </Header>
                }
            >
                <SpaceBetween size={"l"}>
                    <CompetitionsSelect
                        selectedCompetition={selectedCompetition}
                        setSelectedCompetition={setSelectedCompetition}
                        disabledStates={[CompetitionState.STARTED, CompetitionState.FINISHED]}
                    />
                    <Container
                        header={<CompetitionsHeader competition={selectedCompetition} />}
                    >
                    {!!selectedCompetition ? (
                        <QuestionsForm competition={selectedCompetition} />
                    ) : (
                        <NoOpenCompetitionsFlashbar />
                    )}
                    </Container>
                </SpaceBetween>
            </Container>
        </SpaceBetween>
    )
};

export default QuestionsPage;
