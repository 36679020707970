import { Dispatch, Reducer, ReducerAction, ReducerState, useReducer } from "react";
import { useLocalStorageState } from "hooks/useLocalStorageState";


/* eslint-disable  @typescript-eslint/no-explicit-any */
const useLocalStorageReducer = <R extends Reducer<any, any>>(key: string, defaultValue: unknown, reducer: Reducer<any, any>): [ReducerState<R>, Dispatch<ReducerAction<R>>] => {
    const [localStorageState, setLocalStorageState] = useLocalStorageState(
        key,
        defaultValue
    );

    return useReducer((state: any, action: any) => {
        const newState = reducer(state, action);
        setLocalStorageState(newState);
        return newState;
    }, localStorageState);
}

export default useLocalStorageReducer;
