import League from "model/League";
import {API_URL} from "common/const";
import {fetchApiClient} from "common/fetchApiClient";
import {AxiosError} from "axios";
import UnauthorizedError from "api/errors/UnauthorizedError";
import Notification from "../model/Notification";


const getNotifications = async (league: League, token: string | undefined): Promise<Notification[]> => {
    const apiClient = fetchApiClient(token);

    const url = `${API_URL}/notifications/${league?.leagueId}`;

    return await apiClient.get<Notification[]>(url)
        .then(response => {
            return response.data;
        }).catch((error: AxiosError) => {
            console.log(`Error fetching notifications for League(${league?.name})`, error);
            if (!!error.response && error.response.status === 403) {
                throw new UnauthorizedError();
            }
            throw error;
        });
}

export default getNotifications;
