import React from "react";
import SpaceBetween from "@awsui/components-react/space-between";
import NavigationBar from "components/NavigationBar";
import Container from "@awsui/components-react/container";
import Header from "@awsui/components-react/header";
import {Toggle} from "@awsui/components-react";
import {usePrefs} from "hooks/usePrefs";


const PrefsPage: React.FC = () => {
    const [{darkModeEnabled}, dispatch] = usePrefs();

    const toggleDarkMode = (enabled: boolean): void => {
        dispatch({
            darkModeEnabled: enabled,
        });
    }

    return (
        <SpaceBetween size={"l"}>
            <NavigationBar/>
            <Container
                variant={"default"}
                header={
                    <Header variant={"h2"}>
                        Preferencias
                    </Header>
                }
            >
                <Toggle
                    onChange={({ detail }) => toggleDarkMode(detail.checked)}
                    checked={darkModeEnabled}
                >
                    Modo oscuro
                </Toggle>
            </Container>
        </SpaceBetween>
    )
};

export default PrefsPage;
