import SpaceBetween from "@awsui/components-react/space-between";
import React, { useState } from "react";
import NavigationBar from "components/NavigationBar";
import Container from "@awsui/components-react/container";
import Header from "@awsui/components-react/header";
import Competition from "model/Competition";
import CompetitionsSelect from "components/CompetitionsSelect";
import CompetitionsHeader from "components/CompetitionsHeader";
import { CompetitionState } from "model/CompetitionState";
import Textarea from "@awsui/components-react/textarea";
import AddRidersButton from "pages/admin/pages/add_riders/components/AddRidersButton";
import { FlashbarProps } from "@awsui/components-react/flashbar/interfaces";
import Box from "@awsui/components-react/box";
import Link from "@awsui/components-react/link";
import SubmitFlashbar from "components/SubmitFlashbar";


const AddRidersPage: React.FC = () => {

    const [selectedCompetition, setSelectedCompetition] = useState<Competition>();
    const [pointsText, setRidersText] = useState("");
    const [buttonEnabled, setButtonEnabled] = useState(true);
    const [submitMessage, setSubmitMessage] = useState<FlashbarProps.MessageDefinition>();

    return (
        <SpaceBetween size="l">
            <NavigationBar />
            <Container
                header={
                    <Box variant={"span"}>
                        <Header variant="h1">Add riders</Header>
                        <Link variant={"info"} href={"/admin"}>Back</Link>
                    </Box>
                }
            >
                <SpaceBetween size={"m"}>
                    <CompetitionsSelect
                        selectedCompetition={selectedCompetition}
                        setSelectedCompetition={setSelectedCompetition}
                        disabledStates={[CompetitionState.STARTED, CompetitionState.FINISHED]}
                    />
                    {selectedCompetition &&
                        <Container
                            variant={"default"}
                            header={<CompetitionsHeader competition={selectedCompetition} />}
                        >
                            <SpaceBetween size={"m"}>
                                {submitMessage && (
                                    <SubmitFlashbar
                                        submitMessage={submitMessage}
                                        setSubmitMessage={setSubmitMessage}
                                    />
                                )}
                                <Textarea
                                    onChange={({ detail }) => {
                                        setRidersText(detail.value);
                                        setButtonEnabled(true);
                                    }}
                                    value={pointsText}
                                    disableBrowserAutocorrect
                                    disableBrowserSpellcheck
                                    rows={15}
                                    placeholder="Add riders"
                                />
                                <AddRidersButton
                                    competition={selectedCompetition}
                                    ridersText={pointsText}
                                    setRidersText={setRidersText}
                                    buttonEnabled={buttonEnabled}
                                    setButtonEnabled={setButtonEnabled}
                                    setSubmitMessage={setSubmitMessage}
                                />
                            </SpaceBetween>
                        </Container>
                    }
                </SpaceBetween>
            </Container>
        </SpaceBetween>
    )
};

export default AddRidersPage;
