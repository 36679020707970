import AllAnswers from "model/AllAnswers";
import Competition from "model/Competition";
import League from "model/League";
import {fetchApiClient} from "common/fetchApiClient";
import {API_URL} from "common/const";
import UnauthorizedError from "api/errors/UnauthorizedError";
import { AxiosError } from "axios";


const getAnswers = async (competition: Competition, league: League, token: string | undefined): Promise<AllAnswers> => {
    const apiClient = fetchApiClient(token);

    return await apiClient.get<AllAnswers>(`${API_URL}/answers/${competition.code}/${league.leagueId}`)
        .then(response => {
            return response.data;
        }).catch((error: AxiosError) => {
            console.log(`Error fetching answers for Competition(${competition.code}) in League(${league.name})`, error);
            if (!!error.response && error.response.status === 403) {
                throw new UnauthorizedError();
            }
            throw error;
        });
}

export default getAnswers;
