import {NotificationCode} from "./NotificationCode";
import {ButtonDropdownProps} from "@awsui/components-react/button-dropdown/interfaces";
import Competition from "./Competition";


export const getNotificationMenuItem = (code: NotificationCode, competition?: Competition): ButtonDropdownProps.Item => {
    switch (code) {
        case NotificationCode.LINEUP_NOT_DONE:
            return {
                id: `${code}-${competition?.code}`,
                text: `¡Aún no has hecho la alineación para ${competition?.name}!`,
                href: "/lineup",
                iconName: "status-warning"
            };
        case NotificationCode.QUESTIONS_NOT_ANSWERED:
            return {
                id: `${code}-${competition?.code}`,
                text: `¡Aún no has respondido a las preguntas para ${competition?.name}!`,
                href: "/questions",
                iconName: "status-warning"
            };
        default:
            throw Error(`Cannot find menu item for the notification you just passed: ${code}`);
    }
}

class Notification {
    code: NotificationCode;
    competition?: Competition;

    constructor(code: NotificationCode, competition?: Competition) {
        this.code = code;
        this.competition = competition;
    }
}

export default Notification;
