import React from "react";
import Icon from "@awsui/components-react/icon";

const FirstPositionIcon: React.FC = () => {
    return (
        <Icon
            url="https://img.icons8.com/color/96/gold-medal--v1.png"
        />
    );
};

export default FirstPositionIcon;
