import Rider from "model/Rider";
import {fetchApiClient} from "common/fetchApiClient";
import {API_URL} from "common/const";
import TeamAndSynonyms from "model/TeamAndSynonyms";
import { AxiosError } from "axios";
import UnauthorizedError from "api/errors/UnauthorizedError";
import Competition from "model/Competition";


const getTeamSynonyms = async (riders: Rider[], competition: Competition, token: string | undefined): Promise<TeamAndSynonyms[]> => {
    const apiClient = fetchApiClient(token);

    return await apiClient.post<TeamAndSynonyms[]>(`${API_URL}/team_synonyms/${competition.code}`, {
        riders: riders
    }).then(response => {
        return response.data;
    }).catch((error: AxiosError) => {
        console.log(`Error getting team synonyms`, riders, error);
        if (!!error.response && error.response.status === 403) {
            throw new UnauthorizedError();
        }
        throw error;
    });
}

export default getTeamSynonyms;
