import React, {SetStateAction, Dispatch} from "react";
import Button from "@awsui/components-react/button";
import {useMutation} from "react-query";
import saveAnswers from "api/saveAnswers";
import Competition from "model/Competition";
import {FlashbarProps} from "@awsui/components-react/flashbar/interfaces";
import {useAuth} from "hooks/useAuth";
import UserAnswer from "model/UserAnswer";
import parseUserAnswers from "pages/admin/util/parseUserAnswers";
import { IconProps } from "@awsui/components-react";


interface SubmitAnswersButtonProps {
    selectedAnswers: Map<number, number>;
    numberOfQuestions: number;
    competition: Competition;
    buttonEnabled: boolean;
    setButtonEnabled: Dispatch<SetStateAction<boolean>>;
    setSubmitMessage: Dispatch<SetStateAction<FlashbarProps.MessageDefinition | undefined>>;
    buttonIconName: IconProps.Name | undefined;
    setButtonIconName: Dispatch<SetStateAction<IconProps.Name | undefined>>;
}

const SubmitAnswersButton: React.FC<SubmitAnswersButtonProps> = (props) => {

    const selectedAnswers = props.selectedAnswers;
    const competition = props.competition;

    const [{league, user}] = useAuth();

    const {
        mutate: callSaveAnswers,
        isLoading: saveAnswersIsLoading
    } = useMutation((answers: UserAnswer[]) => saveAnswers(competition, league, answers, user?.token), {
        onSuccess: () => {
            props.setSubmitMessage({
                header: "Preguntas guardadas correctamente",
                type: "success",
                content: "",
            });
            props.setButtonIconName("status-positive");
        },
        onError: () => {
            props.setSubmitMessage({
                header: "Error al guardar las preguntas",
                type: "error",
                content: "Prueba a intentarlo de nuevo.",
            });
            props.setButtonIconName("status-negative");
        },
        onSettled: () => {
            props.setButtonEnabled(false);
        }
    });

    const submitAnswers = () => {
        callSaveAnswers(parseUserAnswers(selectedAnswers));
    }

    return (
        <Button
            disabled={!props.buttonEnabled || (selectedAnswers.size < props.numberOfQuestions)}
            iconAlign="left"
            loading={saveAnswersIsLoading}
            variant="primary"
            onClick={submitAnswers}
            iconName={props.buttonIconName}
        >
            Guardar
        </Button>
    );
};

export default SubmitAnswersButton;
