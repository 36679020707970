import League from "model/League";
import {API_URL} from "common/const";
import {fetchApiClient} from "common/fetchApiClient";
import UnauthorizedError from "api/errors/UnauthorizedError";
import { AxiosError } from "axios";


const getLeagues = async (token: string | undefined): Promise<League[]> => {
    const apiClient = fetchApiClient(token);

    return await apiClient.get<League[]>(`${API_URL}/leagues`)
        .then(response => {
            return response.data;
        }).catch((error: AxiosError) => {
            console.log("Error when fetching leagues", error);
            if (!!error.response && error.response.status === 403) {
                throw new UnauthorizedError();
            }
            throw error;
        });
}

export default getLeagues;
