import React, {useState} from "react";
import Container from "@awsui/components-react/container";
import Header from "@awsui/components-react/header";
import SpaceBetween from "@awsui/components-react/space-between";
import NavigationBar from "components/NavigationBar";
import {useAuth} from "hooks/useAuth";
import Box from "@awsui/components-react/box";
import LeagueSelector from "components/LeagueSelector";
import LeaguesTable from "pages/leagues/components/LeaguesTable";
import {FlashbarProps} from "@awsui/components-react/flashbar";
import SubmitFlashbar from "components/SubmitFlashbar";
import NewLeaguesModal from "pages/leagues/components/NewLeagueModal";


const LeaguesPage: React.FC = () => {

    const [{league}] = useAuth();
    const [submitMessage, setSubmitMessage] = useState<FlashbarProps.MessageDefinition>();
    const [showNewLeagueModal, setShowNewLeagueModal] = useState(false);

    return (
        <SpaceBetween size="l">
            <NavigationBar/>
            <Container
                variant="default"
                header={
                    <Header variant="h2">Mis ligas</Header>
                }
                footer={
                    <Box fontSize="heading-s">
                        {"La liga seleccionada es: "}
                        <Box variant="span" fontSize="heading-s" fontWeight="heavy">{league?.name}</Box>
                    </Box>
                }
            >
                {league
                    ? (
                        <LeagueSelector/>
                    )
                    : (
                        <Header variant="h3">{"Escoge una liga!"}</Header>
                    )
                }
            </Container>
            {submitMessage && (
                <SubmitFlashbar
                    submitMessage={submitMessage}
                    setSubmitMessage={setSubmitMessage}
                />
            )}
            <LeaguesTable
                setSubmitMessage={setSubmitMessage}
                showNewLeagueModal={showNewLeagueModal}
                setShowNewLeagueModal={setShowNewLeagueModal}
            />
            <NewLeaguesModal
                showModal={showNewLeagueModal}
                setShowModal={setShowNewLeagueModal}
            />
        </SpaceBetween>
    )
};

export default LeaguesPage;
