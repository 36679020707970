import React from "react";
import "App.css";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import HomePage from "pages/home/HomePage";
import NotFoundPage from "pages/notFound/NotFoundPage";
import AboutPage from "pages/about/AboutPage";
import AuthPage from "pages/auth/AuthPage";
import {AuthProvider} from "hooks/useAuth";
import SignOutPage from "pages/signOut/SignOutPage";
import StandingsPage from "pages/standings/StandingsPage";
import {QueryCache, QueryClient, QueryClientProvider} from "react-query";
import StatsPage from "pages/stats/StatsPage";
import LineupPage from "pages/lineup/LineupPage";
import ProtectedRoute from "components/ProtectedRoute";
import QuestionsPage from "pages/questions/QuestionsPage";
import AdminRoute from "components/AdminRoute";
import AdminPage from "pages/admin/AdminPage";
import AddRidersPage from "pages/admin/pages/add_riders/AddRidersPage";
import AddPointsPage from "pages/admin/pages/add_points/AddPointsPage";
import RecalculatePointsPage from "pages/admin/pages/recalculate_points/RecalculatePointsPage";
import AddCompetitionPage from "pages/admin/pages/add_competition/AddCompetitionPage";
import EditCompetitionPage from "pages/admin/pages/edit_competition/EditCompetitionPage";
import AddQuestionPage from "pages/admin/pages/add_question/AddQuestionPage";
import SelectAnswersPage from "pages/admin/pages/select_answers/SelectAnswersPage";
import LeaguesPage from "pages/leagues/LeaguesPage";
import LeagueRoute from "components/LeagueRoute";
import AddRiderSynonymsPage from "pages/admin/pages/add_rider_synonyms/AddRiderSynonymsPage";
import AddTeamSynonymsPage from "pages/admin/pages/add_team_synonyms/AddTeamSynonymsPage";
import SetBasquenessPage from "pages/admin/pages/set_basqueness/SetBasquenessPage";
import UnauthorizedError from "api/errors/UnauthorizedError";
import UnauthorizedContainer from "components/UnauthorizedContainer";
import DeletePointsPage from "pages/admin/pages/delete_points/DeletePointsPage";
import {PrefsProvider} from "hooks/usePrefs";
import DarkModeContainer from "components/DarkModeContainer";
import PrefsPage from "pages/prefs/PrefsPage";
import PalmaresPage from "./pages/palmares/PalmaresPage";


const App: React.FC = () => {
    return (
        <AuthProvider>
            <PrefsProvider>
                <DarkModeContainer>
                    <QueryClientProvider client={queryClient}>
                        <Router>
                            <AllRoutes/>
                        </Router>
                    </QueryClientProvider>
                </DarkModeContainer>
            </PrefsProvider>
        </AuthProvider>
    )
};

const AllRoutes = () => (
    <Routes>
        <Route path={"/"} element={<UnauthorizedContainer><HomePage/></UnauthorizedContainer>}/>
        <Route path={"/about"} element={<AboutPage/>}/>
        <Route path={"/auth"} element={<AuthPage/>}/>
        <Route path={"/signout"} element={<SignOutPage/>}/>
        <Route path={"/standings"} element={<ProtectedRoute><LeagueRoute><StandingsPage/></LeagueRoute></ProtectedRoute>}/>
        <Route path={"/stats"} element={<ProtectedRoute><StatsPage/></ProtectedRoute>}/>
        <Route path={"/lineup"} element={<ProtectedRoute><LeagueRoute><LineupPage/></LeagueRoute></ProtectedRoute>}/>
        <Route path={"/questions"} element={<ProtectedRoute><LeagueRoute><QuestionsPage/></LeagueRoute></ProtectedRoute>}/>
        <Route path={"/palmares"} element={<ProtectedRoute><LeagueRoute><PalmaresPage/></LeagueRoute></ProtectedRoute>}/>
        <Route path={"/leagues"} element={<ProtectedRoute><LeaguesPage/></ProtectedRoute>}/>
        <Route path={"/admin"} element={<AdminRoute><AdminPage/></AdminRoute>}/>
        <Route path={"/admin/add_riders"} element={<AdminRoute><AddRidersPage/></AdminRoute>}/>
        <Route path={"/admin/add_points"} element={<AdminRoute><AddPointsPage/></AdminRoute>}/>
        <Route path={"/admin/recalculate_points"} element={<AdminRoute><RecalculatePointsPage/></AdminRoute>}/>
        <Route path={"/admin/add_competition"} element={<AdminRoute><AddCompetitionPage/></AdminRoute>}/>
        <Route path={"/admin/edit_competition"} element={<AdminRoute><EditCompetitionPage/></AdminRoute>}/>
        <Route path={"/admin/add_question"} element={<AdminRoute><AddQuestionPage/></AdminRoute>}/>
        <Route path={"/admin/select_answers"} element={<AdminRoute><SelectAnswersPage/></AdminRoute>}/>
        <Route path={"/admin/add_rider_synonyms"} element={<AdminRoute><AddRiderSynonymsPage/></AdminRoute>}/>
        <Route path={"/admin/add_team_synonyms"} element={<AdminRoute><AddTeamSynonymsPage/></AdminRoute>}/>
        <Route path={"/admin/set_basqueness"} element={<AdminRoute><SetBasquenessPage/></AdminRoute>}/>
        <Route path={"/admin/delete_points"} element={<AdminRoute><DeletePointsPage/></AdminRoute>}/>
        <Route path={"/prefs"} element={<ProtectedRoute><PrefsPage/></ProtectedRoute>}/>
        <Route path={"*"} element={<NotFoundPage/>}/>
    </Routes>
);

export default App;

const queryCache = new QueryCache({
    onError: (error) => {
        console.log(error)
    }
})

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnReconnect: true,
            refetchOnWindowFocus: false,
            staleTime: 2*60*1000,
            retry: false,
            useErrorBoundary: (error) => {
                return error instanceof UnauthorizedError;
            }
        },
        mutations: {
            retry: false,
            useErrorBoundary: (error) => {
                return error instanceof UnauthorizedError;
            }
        }
    },
    queryCache: queryCache
});