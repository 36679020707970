import React, {useState} from "react";
import Container from "@awsui/components-react/container";
import Header from "@awsui/components-react/header";
import Link from "@awsui/components-react/link";
import SpaceBetween from "@awsui/components-react/space-between";
import NavigationBar from "components/NavigationBar";
import {useAuth} from "hooks/useAuth";
import {useQuery} from "react-query";
import Competition from "model/Competition";
import getCompetitions from "api/getCompetitions";
import Spinner from "@awsui/components-react/spinner";
import {CompetitionState} from "model/CompetitionState";


const HomePage: React.FC = () => {

    const [{isSignedIn, user}] = useAuth();
    const [openCompetitions, setOpenCompetitions] = useState<Competition[]>([]);

    const {
        isLoading: competitionsIsLoading
    } = useQuery<Competition[], Error>("getCompetitions", () => getCompetitions(user?.token), {
        enabled: isSignedIn,
        onSuccess: (comps) => {
            const openCompetitions = comps.filter(competition => (
                competition.state === CompetitionState.NOT_STARTED
            ));
            setOpenCompetitions(openCompetitions);
        },
        refetchOnWindowFocus: true
    });

    return (
        <SpaceBetween size="l">
            <NavigationBar/>
            <Container
                variant="default"
                header={
                    <Header variant="h2">Bienvenido {user && `${user.name}!`}</Header>
                }
            >
                ¡Bienvenido a la porra de Zirauki! ¡Disfruta de la nueva experiencia!
            </Container>
            <Container
                variant="default"
                header={
                    <Header variant="h2">Sobre la porra</Header>
                }
            >
                Para saber más sobre la porra, ve a <Link variant={"primary"} href={"/about"}>Sobre la porra</Link>.
                {isSignedIn && (
                    <>
                        Para ver resultados de competiciones antiguas, ve a <Link variant={"primary"}
                                                                                  href={"/standings"}>Clasificación</Link>.
                        Para crear o modificar tu alineación o preguntas para una competición que no ha empezado, ve
                        a <Link variant={"primary"} href={"/lineup"}>Alineación</Link> o <Link variant={"primary"}
                                                                                               href={"/questions"}>Preguntas</Link>.
                        Para ver estadísticas sobre competiciones anteriores, ve a <Link variant={"primary"}
                                                                                         href={"/stats"}>Estadísticas</Link>.
                        Para ver las ligas a las que perteneces, ve a <Link variant={"primary"}
                                                                            href={"/leagues"}>Ligas</Link>.
                    </>
                )}
            </Container>
            {isSignedIn && (
                <Container
                    variant="default"
                    header={
                        <Header variant="h2">Competiciones abiertas</Header>
                    }
                >
                    {competitionsIsLoading ? (
                        <Spinner size="big"/>
                    ) : (
                        (openCompetitions && openCompetitions.length > 0) ? (
                            <>
                                Hay {openCompetitions.length} competiciones
                                abiertas: {openCompetitions.map(competition => competition.name).join(", ")}.
                                ¡Corre a hacer tu porra!
                            </>
                        ) : (
                            <>No hay competiciones abiertas</>
                        )
                    )}
                </Container>
            )}
        </SpaceBetween>
    )
};

export default HomePage;
