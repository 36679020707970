import React from "react";
import SpaceBetween from "@awsui/components-react/space-between";
import NavigationBar from "components/NavigationBar";
import Container from "@awsui/components-react/container";
import Header from "@awsui/components-react/header";
import Tabs from "@awsui/components-react/tabs";
import PointsTable from "pages/about/components/PointsTable";


const stagePositionPoints: number[] = [125, 80, 70, 60, 50, 40, 30, 25, 20, 17, 15, 13, 11, 9, 7, 5, 4, 3, 2, 1];
const stageGCPoints: number[] = [30, 15, 10, 5, 2, 1];
const stageTTTPoints: number[] = [50, 25, 5];

const finalGCPoints: number[] = [500, 400, 325, 225, 190, 160, 135, 120, 105, 90, 75, 65, 55, 45, 35, 30, 25, 20, 15, 10, 7, 5, 3, 2, 1];
const finalMountainPoints: number[] = [100];
const finalGreenPoints: number[] = [100];
const finalYoungPoints: number[] = [100];
const finalFirstBasquePoints: number[] = [100];
const finalTeamPoints: number[] = [50];

const questionsPoints: number[] = [50];

const AboutPage: React.FC = () => (
    <SpaceBetween size={"l"}>
        <NavigationBar />
        <Container
            variant={"default"}
            header={
                <Header variant={"h2"}>
                    Sobre la porra
                </Header>
            }
        >
            <Tabs
                tabs={[
                    {
                        label: "Puntos por etapa",
                        id: "pointsPerStageTab",
                        content:
                            <SpaceBetween size="m" direction="horizontal">
                                <PointsTable
                                    title={"Clasificación de la etapa"}
                                    points={stagePositionPoints}
                                />
                                <PointsTable
                                    title={"Clasificación general"}
                                    points={stageGCPoints}
                                />
                                <PointsTable
                                    title={"Contrarreloj por equipos (por ciclista)"}
                                    points={stageTTTPoints}
                                />
                            </SpaceBetween>
                    },
                    {
                        label: "Puntos por la general",
                        id: "pointsPerGCTab",
                        content:
                            <SpaceBetween size="m" direction="horizontal">
                                <PointsTable
                                    title={"Clasificación general"}
                                    points={finalGCPoints}
                                />
                                <PointsTable
                                    title={"Montaña"}
                                    points={finalMountainPoints}
                                />
                                <PointsTable
                                    title={"Regularidad"}
                                    points={finalGreenPoints}
                                />
                                <PointsTable
                                    title={"Jóvenes"}
                                    points={finalYoungPoints}
                                />
                                <PointsTable
                                    title={"Primer vasco"}
                                    points={finalFirstBasquePoints}
                                />
                                <PointsTable
                                    title={"Clasificación por equipos (por ciclista)"}
                                    points={finalTeamPoints}
                                />
                                <PointsTable
                                    title={"Preguntas"}
                                    points={questionsPoints}
                                />
                            </SpaceBetween>
                    }
                ]}
            />
        </Container>
    </SpaceBetween>
);

export default AboutPage;
