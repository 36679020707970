import { Context, createContext, Dispatch, useContext } from "react";
import useLocalStorageReducer from "hooks/useLocalStorageReducer";


interface PrefsState {
    darkModeEnabled: boolean;
}

const defaultValue: PrefsState = {
    darkModeEnabled: window.matchMedia('(prefers-color-scheme: dark)').matches,
}

// Create a context
const PrefsStateContext: Context<PrefsState> = createContext<PrefsState>(defaultValue);
const PrefsDispatchContext: Context<Dispatch<PrefsState>> = createContext<Dispatch<PrefsState>>(undefined as unknown as Dispatch<PrefsState>);

const PrefsProvider: React.FC = ({ children }) => {
    const [state, dispatch] = useLocalStorageReducer(
        "prefs",
        defaultValue,
        prefsStateReducer
    );

    return (
        <PrefsStateContext.Provider value={state}>
            <PrefsDispatchContext.Provider value={dispatch}>
                {children}
            </PrefsDispatchContext.Provider>
        </PrefsStateContext.Provider>
    );
};

const prefsStateReducer = (state: PrefsState, action: PrefsState) => {
    return {
        darkModeEnabled: action.darkModeEnabled,
    };
}

const usePrefs = (): [PrefsState, Dispatch<PrefsState>] => {
    return [usePrefsState(), usePrefsDispatch()];
}

const usePrefsState = (): PrefsState => {
    const context = useContext(PrefsStateContext);

    if (context === undefined) {
        throw new Error(
            "usePrefsState must be used within an PrefsProvider"
        );
    }

    return context;
}

const usePrefsDispatch = (): Dispatch<PrefsState> => {
    const context = useContext(PrefsDispatchContext);

    if (context === undefined) {
        throw new Error(
            "usePrefsDispatch must be used within an PrefsProvider"
        );
    }

    return context;
}

export { PrefsProvider, usePrefs };
