import Rider from "model/Rider";
import {fetchApiClient} from "common/fetchApiClient";
import {API_URL} from "common/const";
import RiderAndSynonyms from "model/RiderAndSynonyms";
import { AxiosError } from "axios";
import UnauthorizedError from "api/errors/UnauthorizedError";


const getRiderSynonyms = async (riders: Rider[], token: string | undefined): Promise<RiderAndSynonyms[]> => {
    const apiClient = fetchApiClient(token);

    return await apiClient.post<RiderAndSynonyms[]>(`${API_URL}/rider_synonyms`, {
        riders: riders
    }).then(response => {
        return response.data;
    }).catch((error: AxiosError) => {
        console.log(`Error getting rider synonyms`, riders, error);
        if (!!error.response && error.response.status === 403) {
            throw new UnauthorizedError();
        }
        throw error;
    });
}

export default getRiderSynonyms;
