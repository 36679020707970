import League from "model/League";
import {fetchApiClient} from "common/fetchApiClient";
import {API_URL} from "common/const";
import { AxiosError } from "axios";
import UnauthorizedError from "api/errors/UnauthorizedError";
import WrongPasswordError from "api/errors/WrongPasswordError";
import AlreadyInLeagueError from "./errors/AlreadyInLeagueError";


const addMembership = async (league: League, token: string | undefined): Promise<void> => {
    const apiClient = fetchApiClient(token);

    await apiClient.put(`${API_URL}/memberships`, {
        league_id: league.leagueId,
        password: league.password
    }).catch((error: AxiosError) => {
        console.log(`Error adding membership for League(${league.name})`, error);
        if (!!error.response) {
            if (error.response.status === 403) {
                throw new UnauthorizedError();
            }
            if (error.response.status === 401) {
                throw new WrongPasswordError();
            }
            if (error.response.status === 409) {
                throw new AlreadyInLeagueError();
            }
        }
        throw error;
    });
}

export default addMembership;
