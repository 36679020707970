import React, { SetStateAction, Dispatch } from "react";
import Button from "@awsui/components-react/button";
import { useMutation } from "react-query";
import Competition from "model/Competition";
import { FlashbarProps } from "@awsui/components-react/flashbar/interfaces";
import deletePoints from "api/deletePoints";
import Box from "@awsui/components-react/box";
import {useAuth} from "hooks/useAuth";


interface DeletePointsButtonProps {
    competition: Competition;
    stage: number;
    buttonEnabled: boolean;
    setButtonEnabled: Dispatch<SetStateAction<boolean>>;
    setSubmitMessage: Dispatch<SetStateAction<FlashbarProps.MessageDefinition | undefined>>;
}

const DeletePointsButton: React.FC<DeletePointsButtonProps> = (props) => {

    const competition = props.competition;
    const stage = props.stage;

    const [{user}] = useAuth();

    const {
        mutate: deletePointsMutation,
        isLoading: deletePointsIsLoading
    } = useMutation(() => deletePoints(competition, stage, user?.token), {
        onSuccess: () => {
            props.setSubmitMessage({
                header: "Points deleted correctly",
                type: "success",
                content: "",
            })
        },
        onError: () => {
            props.setSubmitMessage({
                header: "Error when deleting points",
                type: "error",
                content: "Try to refresh the page.",
            })
        },
        onSettled: () => {
            props.setButtonEnabled(false);
        }
    }
    );

    const submitPoints = () => {
        deletePointsMutation();
    }

    return (
        <Box float="right">
            <Button
                disabled={!props.buttonEnabled}
                iconAlign="left"
                loading={deletePointsIsLoading}
                variant="primary"
                onClick={submitPoints}
            >
                Submit
            </Button>
        </Box>
    );
};

export default DeletePointsButton;
