import React, { Dispatch, SetStateAction, useState } from "react";
import Table, { TableProps } from "@awsui/components-react/table";
import Header from "@awsui/components-react/header";
import Competition from "model/Competition";
import { useQuery } from "react-query";
import useTable from "hooks/useTable";
import Pagination from "@awsui/components-react/pagination";
import TextFilter from "@awsui/components-react/text-filter";
import CollectionPreferences from "@awsui/components-react/collection-preferences";
import Rider from "model/Rider";
import getRiders from "api/getRiders";
import LineupsValue from "pages/lineup/components/LineupsValue";
import SubmitLineupsButton from "pages/lineup/components/SubmitLineupsButton";
import { IconProps } from "@awsui/components-react/icon/interfaces";
import CompetitionsHeader from "components/CompetitionsHeader";
import { useAuth } from "hooks/useAuth";
import { Navigate } from "react-router-dom";
import FlagIcon from "components/icons/FlagIcon";
import {formatNumber} from "common/formatNumber";
import { FlashbarProps } from "@awsui/components-react";
import RiderPopover from "components/RiderPopover";


interface LineupsTableProps {
    competition: Competition;
    setSubmitMessage: Dispatch<SetStateAction<FlashbarProps.MessageDefinition | undefined>>;
}

const columnDefinitions: TableProps.ColumnDefinition<Rider>[] = [
    {
        id: "name",
        header: "Nombre",
        cell: (item: Rider) => <RiderPopover rider={item} />,
        sortingField: "name"
    },
    {
        id: "team",
        header: "Equipo",
        cell: (item: Rider) => item.team || "-",
        sortingField: "team"
    },
    {
        id: "country",
        header: "País",
        cell: (item: Rider) => item.country && <FlagIcon country={item.country}/>,
        sortingField: "country"
    },
    {
        id: "value",
        header: "Valor",
        cell: (item: Rider) => `${formatNumber(item.value, 1)} mls.`,
        sortingField: "value"
    }
];

const LineupsTable: React.FC<LineupsTableProps> = (props) => {

    const competition = props.competition;

    const [{ league, user }] = useAuth();

    if (!league) {
        return (<Navigate to={"/"} />);
    }

    const [buttonEnabled, setButtonEnabled] = useState(true);
    const [buttonIconName, setButtonIconName] = useState<IconProps.Name | undefined>();

    const {
        isLoading: ridersIsLoading,
        data: riders,
        error: getRidersError
    } = useQuery<Rider[], Error>(`getRiders-${competition.code}`, () => getRiders(competition, league, user?.token), {
        onSuccess: (returnedRiders: Rider[]) => {
            selectRiders(returnedRiders.filter(rider => rider.selected));
        }
    });

    const {
        preferences,
        setPreferences,
        items,
        actions,
        collectionProps,
        filterProps,
        paginationProps,
        selectedItems,
        collectionPreferencesProps
    } = useTable(riders || [], {
        emptyStateSubtitle: getRidersError as Error,
        emptyStateTitle: "No hay corredores.",
        pageSize: 20
    });

    const selectRiders = (riders: Rider[]) => {
        actions.setSelectedItems(riders);
        setButtonEnabled(true);
        setButtonIconName(undefined);
    }

    return (
        <Table
            {...collectionProps}
            loadingText="Cargando los corredores"
            columnDefinitions={columnDefinitions}
            items={items}
            loading={ridersIsLoading}
            wrapLines={false}
            header={
                <Header
                    counter={
                        selectedItems.length
                            ? `(${selectedItems.length}/12) `
                            : "(0/12) "
                    }
                    info={
                        <LineupsValue selectedRiders={selectedItems} />
                    }
                    actions={
                        <SubmitLineupsButton
                            selectedRiders={selectedItems}
                            competition={competition}
                            league={league}
                            buttonEnabled={buttonEnabled}
                            setButtonEnabled={setButtonEnabled}
                            buttonIconName={buttonIconName}
                            setButtonIconName={setButtonIconName}
                            setSubmitMessage={props.setSubmitMessage}
                        />
                    }
                >
                    <CompetitionsHeader competition={competition} />
                </Header>
            }
            selectionType="multi"
            onSelectionChange={({ detail }) => selectRiders(detail.selectedItems as Rider[])}
            selectedItems={selectedItems}
            pagination={
                <Pagination
                    {...paginationProps}
                    ariaLabels={{
                        nextPageLabel: "Siguiente página",
                        previousPageLabel: "Página anterior",
                        pageLabel: pageNumber => `Página ${pageNumber}`
                    }}
                />
            }
            filter={
                <TextFilter
                    {...filterProps}
                    filteringPlaceholder="Buscar por texto"
                />
            }
            preferences={
                <CollectionPreferences
                    {...collectionPreferencesProps}
                    preferences={preferences}
                    onConfirm={({ detail }) => setPreferences(detail)}
                />
            }
        />
    );
};

export default LineupsTable;