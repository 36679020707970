import Rider from "model/Rider";
import {fetchApiClient} from "common/fetchApiClient";
import {API_URL} from "common/const";
import { AxiosError } from "axios";
import UnauthorizedError from "api/errors/UnauthorizedError";


const getAllRiders = async (token: string | undefined): Promise<Rider[]> => {
    const apiClient = fetchApiClient(token);

    return await apiClient.get<Rider[]>(`${API_URL}/riders`)
        .then(response => {
            return response.data;
        }).catch((error: AxiosError) => {
            console.log(`Error fetching all riders`, error);
            if (!!error.response && error.response.status === 403) {
                throw new UnauthorizedError();
            }
            throw error;
        });
}

export default getAllRiders;
