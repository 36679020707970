import React from "react";
import Icon from "@awsui/components-react/icon";
import {Box, SpaceBetween} from "@awsui/components-react";
import {COUNTRIES_MAP} from "common/const";


interface FlagIconProps {
    country: string;
}


const FlagIcon: React.FC<FlagIconProps> = (props) => {
    const countryCode = COUNTRIES_MAP.get(props.country);
    const url = `https://flagcdn.com/40x30/${countryCode}.png`;
    return (
        <Box variant={"span"}>
            <SpaceBetween size={"xxs"} direction={"horizontal"}>
                {!isBasque(countryCode) ? (
                    <Icon size={"normal"} url={url} alt={props.country}/>
                ) : (
                    <Icon
                        size={"normal"}
                        svg={
                            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                 width="16px" height="16px" viewBox="0 0 16 16" focusable="false">
                                <image id="image0" width="16" height="16" x="0" y="0"
                                       href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
                                       AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAXVBMVEX////jVVXWaT3/TFT6
                                       mJ7fYECmiyB5qwTDdjH+S1X/S1X4wMP5T1GwgyV0rgGUlxTqWkjfYkKJnw14qwO/eC79TFT7TlO1
                                       gCh1rQGRmhLxxb/wx77V2q7p7t7///+kJPq7AAAAB3RSTlMACVBAYQjpiXZdpQAAAAFiS0dEAIgF
                                       HUgAAAAJcEhZcwAAAG8AAABvAfGi3EMAAAAHdElNRQfmAx8UAiRWCeLZAAAAZklEQVQY04WOSRKA
                                       IAwEo6JIAgKyuID//6aoxxzsQ5apymQAfun64UGIt/UjTHJWiEjUiprlBNos1n2Cs4vRgOjXEBNR
                                       imH1iE3AvO3HeR77ltsC9FDqddXyjlxgJ8yUvWXBWPRfbhN/CY1DW8f/AAAAJXRFWHRkYXRlOmNy
                                       ZWF0ZQAyMDIyLTAzLTMxVDIwOjAyOjM2KzAwOjAwdKzFhwAAACV0RVh0ZGF0ZTptb2RpZnkAMjAy
                                       Mi0wMy0zMVQyMDowMjozNiswMDowMAXxfTsAAAAZdEVYdFNvZnR3YXJlAHd3dy5pbmtzY2FwZS5v
                                       cmeb7jwaAAAAAElFTkSuQmCC"/>
                            </svg>
                        }
                        alt={props.country}
                    />
                )}
                {props.country}
            </SpaceBetween>
        </Box>
    );
};

const isBasque = (code: string | undefined): boolean => {
    return code === "eus";
}

export default FlagIcon;
