import Box from "@awsui/components-react/box";
import Popover from "@awsui/components-react/popover";
import React from "react";
import SpaceBetween from "@awsui/components-react/space-between";
import Answer from "model/Answer";
import {StatusIndicator} from "@awsui/components-react";


interface PossibleAnswersPopoverProps {
    answers: Answer[];
    userAnswerId: number;
    userAnswerText: string;
    correctAnswerId: number | undefined;
}

const PossibleAnswersPopover: React.FC<PossibleAnswersPopoverProps> = (props) => {

    const answers = props.answers;
    const userAnswerId = props.userAnswerId;
    const userAnswerText = props.userAnswerText;
    const correctAnswerId = props.correctAnswerId;

    return (
        <Popover
            position="top"
            size="medium"
            triggerType="text"
            content={
                <SpaceBetween direction={"vertical"} size={"s"}>
                    {answers.map(answer => {
                        if (correctAnswerId) {
                            if (answer.answerId === correctAnswerId) {
                                return <StatusIndicator type="success">{answer.text}</StatusIndicator>
                            } else if (answer.answerId === userAnswerId) {
                                return <StatusIndicator type="error">{answer.text}</StatusIndicator>
                            } else {
                                return <Box variant="span">{answer.text}</Box>;
                            }
                        } else {
                            const variant = answer.answerId === userAnswerId ? "strong" : "span";
                            return <Box variant={variant}>{answer.text}</Box>;
                        }
                    })}
                </SpaceBetween>
            }
        >
            <Box variant="span">{userAnswerText}</Box>
        </Popover>
    );
};

export default PossibleAnswersPopover;
