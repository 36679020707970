import React, { SetStateAction, Dispatch } from "react";
import Button from "@awsui/components-react/button";
import { useMutation } from "react-query";
import { FlashbarProps } from "@awsui/components-react/flashbar/interfaces";
import { useAuth } from "hooks/useAuth";
import NewLeague from "model/NewLeague";
import League from "model/League";
import createNewLeague from "api/createNewLeague";
import LeagueAlreadyExistsError from "api/errors/LeagueAlreadyExistsError";


interface CreateNewLeagueButtonProps {
    newLeague: NewLeague;
    setSubmitMessage: Dispatch<SetStateAction<FlashbarProps.MessageDefinition | undefined>>;
    buttonEnabled: boolean;
    setButtonEnabled: Dispatch<SetStateAction<boolean>>;
    setShowModal: Dispatch<SetStateAction<boolean>>;
}

const CreateNewLeagueButton: React.FC<CreateNewLeagueButtonProps> = (props) => {

    const newLeague = props.newLeague;

    const [{ user, isSignedIn, isAdmin }, dispatch] = useAuth();

    const {
        isLoading: createLeagueIsLoading,
        mutate: callCreateLeague
    } = useMutation<League, Error, NewLeague>((newLeague: NewLeague) => createNewLeague(newLeague, user?.token), {
        onSuccess: (league: League) => {
            props.setSubmitMessage({
                header: `¡Liga ${newLeague.name} creada!`,
                type: "success",
                content: "",
            });
            dispatch({
                isSignedIn: isSignedIn,
                isAdmin: isAdmin,
                league: league,
                user: {
                    ...user,
                    leagues: [
                        ...(user?.leagues || []),
                        league
                    ]
                }
            });
        },
        onError: (error: Error) => {
            if (error instanceof LeagueAlreadyExistsError) {
                props.setSubmitMessage({
                    header: `Error al crear la liga ${newLeague.name}`,
                    type: "error",
                    content: "Ya hay una liga con ese nombre.",
                });
            } else {
                props.setSubmitMessage({
                    header: `Error al crear la liga ${newLeague.name}`,
                    type: "error",
                    content: "Prueba a intentarlo de nuevo.",
                });
            }
        },
        onSettled: () => {
            props.setButtonEnabled(false);
        }
    });

    const createLeague = () => {
        const password = btoa(props.newLeague.password || "");
        callCreateLeague({
            ...props.newLeague,
            password: password
        });
    }

    return (
            <Button
                loading={createLeagueIsLoading}
                variant="primary"
                onClick={createLeague}
                disabled={!props.buttonEnabled}
            >
                Crear liga
            </Button>
        );
};

export default CreateNewLeagueButton;
