import React from "react";
import SpaceBetween from "@awsui/components-react/space-between";
import Container from "@awsui/components-react/container";
import Header from "@awsui/components-react/header";
import Competition from "model/Competition";
import Tabs from "@awsui/components-react/tabs";
import AllLineupsComponent from "pages/standings/components/AllLineupsComponent";
import AllAnswersComponent from "pages/standings/components/AllAnswersComponent";
import {CompetitionState} from "model/CompetitionState";


interface AllResultsProps {
    competition: Competition;
}

const AllResults: React.FC<AllResultsProps> = (props) => {

    return props.competition.state !== CompetitionState.NOT_STARTED ? (
        <SpaceBetween size={"l"}>
            <Container
                variant={"default"}
                header={
                    <Header variant={"h2"}>
                        Todas las porras
                    </Header>
                }
            >
                <Tabs
                    tabs={[
                        {
                            label: "Alineaciones",
                            id: "allLineupsTab",
                            content:
                                <AllLineupsComponent competition={props.competition} />
                        },
                        {
                            label: "Preguntas",
                            id: "allQuestionsTab",
                            content:
                                <AllAnswersComponent competition={props.competition} />
                        }
                    ]}
                />
            </Container>
        </SpaceBetween>
    ) : null;
};

export default AllResults;
