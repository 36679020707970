import {fetchApiClient} from "common/fetchApiClient";
import {API_URL} from "common/const";
import RiderAndSynonyms from "model/RiderAndSynonyms";
import RiderSynonym from "model/RiderSynonym";
import UnauthorizedError from "api/errors/UnauthorizedError";
import { AxiosError } from "axios";


const addRiderSynonyms = async (synonyms: RiderSynonym[], token: string | undefined): Promise<void> => {
    const apiClient = fetchApiClient(token);

    await apiClient.put<RiderAndSynonyms[]>(`${API_URL}/rider_synonyms`, {
        synonyms: synonyms
    }).catch((error: AxiosError) => {
        console.log(`Error setting rider synonyms`, synonyms, error);
        if (!!error.response && error.response.status === 403) {
            throw new UnauthorizedError();
        }
        throw error;
    });
}

export default addRiderSynonyms;
