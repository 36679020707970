import Competition from "model/Competition";
import {API_URL} from "common/const";
import {fetchApiClient} from "common/fetchApiClient";
import UserAnswer from "model/UserAnswer";
import { AxiosError } from "axios";
import UnauthorizedError from "api/errors/UnauthorizedError";


const selectAnswers = async (competition: Competition, selectedAnswers: UserAnswer[], token: string | undefined): Promise<void> => {
    const apiClient = fetchApiClient(token);

    await apiClient.post(`${API_URL}/answers/${competition.code}`, {
        answers: selectedAnswers
    }).catch((error: AxiosError) => {
        console.log(`Error selecting answers for Competition(${competition.code})`, selectedAnswers, error);
        if (!!error.response && error.response.status === 403) {
            throw new UnauthorizedError();
        }
        throw error;
    });
}

export default selectAnswers;
