import Box from "@awsui/components-react/box";
import { ReactNode } from "react";

interface KeyValueItemProps {
    keyItem: ReactNode;
    valueItem: ReactNode;
}

const KeyValueItem: React.FC<KeyValueItemProps> = (props) => {
    return (
        <div>
            <Box variant="awsui-key-label">{props.keyItem}</Box>
            <div>{props.valueItem}</div>
        </div>
    );
};

export default KeyValueItem;
