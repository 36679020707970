import React, {Dispatch, SetStateAction, useState} from "react";
import {Modal} from "@awsui/components-react";
import Header from "@awsui/components-react/header";
import Box from "@awsui/components-react/box";
import {FlashbarProps} from "@awsui/components-react/flashbar/interfaces";
import SubmitFlashbar from "components/SubmitFlashbar";
import SpaceBetween from "@awsui/components-react/space-between";
import Form from "@awsui/components-react/form";
import Button from "@awsui/components-react/button";
import FormField from "@awsui/components-react/form-field";
import Input from "@awsui/components-react/input";
import League from "model/League";
import JoinLeagueButton from "./JoinLeagueButton";


interface JoinLeagueModalProps {
    showModal: boolean;
    setShowModal: Dispatch<SetStateAction<boolean>>;
    league: League | undefined;
    setLeague: Dispatch<SetStateAction<League | undefined>>;
}

const JoinLeagueModal: React.FC<JoinLeagueModalProps> = (props) => {

    const league = props.league;

    const [submitMessage, setSubmitMessage] = useState<FlashbarProps.MessageDefinition>();
    const [joinButtonEnabled, setJoinButtonEnabled] = useState(true);

    const dismissModal = () => {
        props.setShowModal(false);
        setSubmitMessage(undefined);
    }

    const changePassword = (newPassword: string): void => {
        league && props.setLeague({
            ...league,
            password: newPassword
        });
        setJoinButtonEnabled(true);
    }

    return !!league ? (
        <Modal
            visible={props.showModal}
            onDismiss={dismissModal}
            header={<Header variant={"h2"}>Únete a {league.name}</Header>}
        >
            <SpaceBetween size={"s"}>
                <SpaceBetween size={"m"}>
                    {submitMessage && (
                        <SubmitFlashbar
                            submitMessage={submitMessage}
                            setSubmitMessage={setSubmitMessage}
                        />
                    )}
                    <form onSubmit={e => e.preventDefault()}>
                        <Form
                            actions={
                                <Box textAlign={"right"}>
                                    <SpaceBetween direction="horizontal" size="xs">
                                        <Button formAction="none" variant="normal" onClick={dismissModal}>
                                            Cerrar
                                        </Button>
                                        <JoinLeagueButton
                                            league={league}
                                            setSubmitMessage={setSubmitMessage}
                                            buttonEnabled={joinButtonEnabled}
                                            setButtonEnabled={setJoinButtonEnabled}
                                        />
                                    </SpaceBetween>
                                </Box>
                            }
                        >
                            <FormField key={"password"} label={<Header variant="h2">Contraseña</Header>}>
                                <Input
                                    inputMode={"text"}
                                    type={"password"}
                                    onChange={({detail}) => changePassword(detail.value)}
                                    value={league?.password || ""}
                                    disableBrowserAutocorrect
                                />
                            </FormField>
                        </Form>
                    </form>
                </SpaceBetween>
            </SpaceBetween>
        </Modal>
    ) : null;
};

export default JoinLeagueModal;
