import SpaceBetween from "@awsui/components-react/space-between";
import React, {ReactNode, useState} from "react";
import NavigationBar from "components/NavigationBar";
import Container from "@awsui/components-react/container";
import Header from "@awsui/components-react/header";
import {FlashbarProps} from "@awsui/components-react/flashbar/interfaces";
import FormField from "@awsui/components-react/form-field";
import Competition from "model/Competition";
import Form from "@awsui/components-react/form";
import Input from "@awsui/components-react/input";
import Button from "@awsui/components-react/button";
import AddQuestionButton from "pages/admin/pages/add_question/components/AddQuestionButton";
import CompetitionsSelect from "components/CompetitionsSelect";
import {CompetitionState} from "model/CompetitionState";
import NewQuestion from "model/admin/NewQuestion";
import {QuestionState} from "model/QuestionState";
import Box from "@awsui/components-react/box";
import Link from "@awsui/components-react/link";
import SubmitFlashbar from "components/SubmitFlashbar";


const AddQuestionPage: React.FC = () => {

    const [competition, setCompetition] = useState<Competition>();
    const [question, setQuestion] = useState<NewQuestion>({
        text: "",
        state: QuestionState.NOT_ANSWERED
    });
    const [buttonEnabled, setButtonEnabled] = useState(true);
    const [submitMessage, setSubmitMessage] = useState<FlashbarProps.MessageDefinition>();

    const changeAnswer = (newText: string, index: number): void => {
        const answers = question.answers || [];
        answers[index].text = newText;
        setQuestion({
            ...question,
            answers: answers,
        });
    }

    const addAnswer = () => {
        const answers = question.answers || [];
        answers.push({ text: "" });
        setQuestion({
            ...question,
            answers: answers,
        })
    }

    const removeAnswer = (index: number) => {
        const answers = question.answers || [];
        answers.splice(index, 1);
        setQuestion({
            ...question,
            answers: answers,
        })
    }

    const changeQuestion = (question: NewQuestion): void => {
        setQuestion(question);
        setButtonEnabled(true);
    }

    const getAnswersAsFormFields = (): ReactNode[] | undefined => {
        return question?.answers?.map((answer, index) => {
            return (
                <FormField
                    key={`answer${index + 1}`}
                    label={<Header variant="h2">Answer {`${index + 1}`} <Button iconName="close" variant="inline-icon" onClick={() => removeAnswer(index)}/></Header>}
                >
                    <Input
                        inputMode="text"
                        onChange={({ detail }) => changeAnswer(detail.value, index)}
                        value={answer.text}
                    />
                </FormField>
            );
        });
    }

    return (
        <SpaceBetween size="l">
            <NavigationBar/>
            <Container
                header={
                    <Box variant={"span"}>
                        <Header variant="h1">Add question</Header>
                        <Link variant={"info"} href={"/admin"}>Back</Link>
                    </Box>
                }
            >
                <SpaceBetween size={"m"}>
                    <CompetitionsSelect
                        selectedCompetition={competition}
                        setSelectedCompetition={setCompetition}
                        disabledStates={[CompetitionState.STARTED, CompetitionState.FINISHED]}
                    />
                    {submitMessage && (
                        <SubmitFlashbar
                            submitMessage={submitMessage}
                            setSubmitMessage={setSubmitMessage}
                        />
                    )}
                    <form onSubmit={e => e.preventDefault()}>
                        <Form
                            actions={
                                <SpaceBetween direction="horizontal" size="xs">
                                    <Button formAction="none" variant="link" href="/">
                                        Cancelar
                                    </Button>
                                    <AddQuestionButton
                                        competition={competition}
                                        question={question}
                                        setQuestion={setQuestion}
                                        buttonEnabled={buttonEnabled}
                                        setButtonEnabled={setButtonEnabled}
                                        setSubmitMessage={setSubmitMessage}
                                    />
                                </SpaceBetween>
                            }
                        >
                            <SpaceBetween direction="vertical" size="l">
                                <FormField key={"question"} label={<Header variant="h2">Question</Header>}>
                                    <Input
                                        inputMode="text"
                                        onChange={({ detail }) => changeQuestion({
                                            ...question,
                                            text: detail.value
                                        })}
                                        value={question?.text || ""}
                                    />
                                </FormField>
                                <SpaceBetween size="m">
                                    <Header>Answers <Button iconName="add-plus" variant="inline-icon" onClick={addAnswer} /></Header>
                                    {getAnswersAsFormFields()}
                                </SpaceBetween>
                            </SpaceBetween>
                        </Form>
                    </form>
                </SpaceBetween>
            </Container>
        </SpaceBetween>
    )
};

export default AddQuestionPage;
