import React from "react";
import Table, {TableProps} from "@awsui/components-react/table";
import Box from "@awsui/components-react/box";
import Header from "@awsui/components-react/header";
import Rider from "model/Rider";
import useTable from "hooks/useTable";
import Pagination from "@awsui/components-react/pagination";
import CollectionPreferences from "@awsui/components-react/collection-preferences";
import {formatNumber} from "common/formatNumber";
import RiderPopover from "components/RiderPopover";


interface AllLineupsTableProps {
    username: string;
    riders: Rider[];
    points: number;
}

const columnDefinitions: TableProps.ColumnDefinition<Rider>[] = [
    {
        id: "name",
        header: "Nombre",
        cell: (rider: Rider) => <RiderPopover rider={rider} />,
        sortingField: "name"
    },
    {
        id: "value",
        header: "Valor",
        cell: (rider: Rider) => `${formatNumber(rider.value, 1)} mls.` || "-",
        sortingField: "value"
    },
    {
        id: "points",
        header: "Puntos",
        cell: (rider: Rider) => rider.points || "0",
        sortingField: "points"
    }
];

const AllLineupsTable: React.FC<AllLineupsTableProps> = (props) => {

    const {
        preferences,
        setPreferences,
        items,
        collectionProps,
        paginationProps,
        collectionPreferencesProps
    } = useTable(props.riders || [], {
        emptyStateSubtitle: new Error(),
        emptyStateTitle: "No hay ciclistas.",
        pageSize: 12
    });

    return (
        <Table
            {...collectionProps}
            loadingText="Cargando las estadísticas"
            columnDefinitions={columnDefinitions}
            items={items}
            loading={false}
            wrapLines={false}
            pagination={
                <Pagination
                    {...paginationProps}
                    ariaLabels={{
                        nextPageLabel: "Siguiente página",
                        previousPageLabel: "Página anterior",
                        pageLabel: pageNumber => `Página ${pageNumber}`
                    }}
                />
            }
            preferences={
                <CollectionPreferences
                    {...collectionPreferencesProps}
                    preferences={preferences}
                    onConfirm={({detail}) => setPreferences(detail)}
                />
            }
            header={<Header variant="h2">{props.username}</Header>}
            footer={<Box variant="h4" textAlign="right" color="text-status-info">{props.points} puntos</Box>}
        />
    );
};

export default AllLineupsTable;