import {fetchApiClient} from "common/fetchApiClient";
import {API_URL} from "common/const";
import { AxiosError } from "axios";
import UnauthorizedError from "api/errors/UnauthorizedError";
import NewLeague from "model/NewLeague";
import League from "model/League";
import LeagueAlreadyExistsError from "api/errors/LeagueAlreadyExistsError";


const createNewLeague = async (newLeague: NewLeague, token: string | undefined): Promise<League> => {
    const apiClient = fetchApiClient(token);

    return await apiClient.put(`${API_URL}/leagues`, {
        league: newLeague
    }).then(response => {
        return response.data;
    }).catch((error: AxiosError) => {
        console.log(`Error creating League(${newLeague.name})`, error);
        if (!!error.response){
            if (error.response.status === 403) {
                throw new UnauthorizedError();
            }
            if (error.response.status === 409) {
                throw new LeagueAlreadyExistsError();
            }
        }
        throw error;
    });
}

export default createNewLeague;
