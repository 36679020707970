import React, { ReactNode, useState } from "react";
import Competition from "model/Competition";
import { useQuery } from "react-query";
import getQuestions from "api/getQuestions";
import Form from "@awsui/components-react/form";
import SpaceBetween from "@awsui/components-react/space-between";
import Button from "@awsui/components-react/button";
import FormField from "@awsui/components-react/form-field";
import Question from "model/Question";
import Spinner from "@awsui/components-react/spinner";
import SelectAnswersButton from "pages/admin/pages/select_answers/components/SelectAnswersButton";
import { FlashbarProps } from "@awsui/components-react/flashbar/interfaces";
import Header from "@awsui/components-react/header";
import Select from "@awsui/components-react/select";
import Flashbar from "@awsui/components-react/flashbar";
import { QuestionState } from "model/QuestionState";
import { useAuth } from "hooks/useAuth";
import { Navigate } from "react-router-dom";
import SubmitFlashbar from "components/SubmitFlashbar";


interface SelectAnswersFormProps {
    competition: Competition;
}

const SelectAnswersForm: React.FC<SelectAnswersFormProps> = (props) => {

    const competition = props.competition;

    const [{ league, user }] = useAuth();

    if (!league) {
        return (<Navigate to={"/leagues"} />);
    }

    const [answers, setAnswers] = useState<Map<number, number>>(new Map());
    const [buttonEnabled, setButtonEnabled] = useState(true);
    const [submitMessage, setSubmitMessage] = useState<FlashbarProps.MessageDefinition>();

    const {
        isLoading: questionsIsLoading,
        data: questions,
        error: getQuestionsError
    } = useQuery<Question[], Error>(`getQuestions-${competition.code}`, () => getQuestions(competition, league, user?.token), {
        onSuccess: (returnedQuestions: Question[]) => {
            returnedQuestions.filter(question => {
                return question.state === QuestionState.ANSWERED && question.answerId !== undefined;
            }).forEach(question => {
                changeAnswers(
                    question.questionId,
                    question.answerId ? question.answerId : 0
                );
            });
        }
    });

    const changeAnswers = (questionId: number, answerId: number): void => {
        setAnswers(new Map(answers.set(questionId, answerId)));
        setButtonEnabled(true);
    }

    const getQuestionsAsFormFields = (questions: Question[]): ReactNode[] => {
        return questions.map(question => {
            const labelText: string = question.answers.filter(answer => {
                return answer.answerId === answers.get(question.questionId);
            }).find(text => text)?.text || "-";
            return (
                <FormField key={question.questionId} label={<Header variant="h2">{question.text}</Header>}>
                    <Select
                        selectedOption={{
                            label: `${labelText}`,
                            value: `${answers.get(question.questionId)}`
                        }}
                        onChange={({ detail }) => (
                            changeAnswers(question.questionId, parseInt(detail.selectedOption.value || ""))
                        )}
                        options={question.answers.map(answer => ({
                            label: answer.text,
                            value: `${answer.answerId}`
                        }))}
                        selectedAriaLabel="Selected"
                    />
                </FormField>
            )
        })
    }

    if (getQuestionsError) {
        return (
            <Flashbar
                items={[{
                    header: "Error al cargar las preguntas",
                    type: "error",
                    content: "Prueba a recargar la página.",
                    dismissible: false,
                }]}
            />
        );
    }

    if (questionsIsLoading || !questions) {
        return (
            <Spinner />
        );
    }

    return (
        <form onSubmit={e => e.preventDefault()}>
            <Form
                actions={
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button formAction="none" variant="link" href="/">
                            Cancelar
                        </Button>
                        <SelectAnswersButton
                            selectedAnswers={answers}
                            numberOfQuestions={questions.length}
                            competition={competition}
                            buttonEnabled={buttonEnabled}
                            setButtonEnabled={setButtonEnabled}
                            setSubmitMessage={setSubmitMessage}
                        />
                    </SpaceBetween>
                }
            >
                <SpaceBetween direction="vertical" size="l">
                    {submitMessage && (
                        <SubmitFlashbar
                            submitMessage={submitMessage}
                            setSubmitMessage={setSubmitMessage}
                        />
                    )}
                    {questions && getQuestionsAsFormFields(questions)}
                </SpaceBetween>
            </Form>
        </form>
    );

};

export default SelectAnswersForm;