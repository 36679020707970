import React from "react";
import Table, {TableProps} from "@awsui/components-react/table";
import Header from "@awsui/components-react/header";
import PalmaresItem from "model/PalmaresItem";
import getPalmares from "api/getPalmares";
import {useQuery} from "react-query";
import useTable from "hooks/useTable";
import Pagination from "@awsui/components-react/pagination";
import TextFilter from "@awsui/components-react/text-filter";
import CollectionPreferences from "@awsui/components-react/collection-preferences";
import {useAuth} from "hooks/useAuth";
import Box from "@awsui/components-react/box";
import PositionIcon from "../../../components/icons/PositionIcon";
import {Navigate} from "react-router-dom";


const PalmaresTable: React.FC = () => {

    const columnDefinitions: TableProps.ColumnDefinition<PalmaresItem>[] = [
        {
            id: "position",
            header: "Puesto",
            cell: (item: PalmaresItem) => item.position || "-",
            sortingField: "position"
        },
        {
            id: "username",
            header: "Usuario",
            cell: (item: PalmaresItem) =>
                <Box variant="span">
                    <PositionIcon length={standings?.length} position={item.position}/>
                    {item.user || "-"}
                </Box>,
            sortingField: "user"
        },
        {
            id: "points",
            header: "Puntos",
            cell: (item: PalmaresItem) => item.points || "-",
            sortingField: "points"
        }
    ];

    const [{league, user}] = useAuth();

    if (!league) {
        return (<Navigate to={"/"}/>);
    }

    const {
        isLoading: standingsIsLoading,
        data: standings,
        error: getPalmaresError
    } = useQuery<PalmaresItem[], Error>(`getPalmares-${league?.leagueId}`, () => getPalmares(league, user?.token));

    const {
        preferences,
        setPreferences,
        items,
        collectionProps,
        filterProps,
        paginationProps,
        collectionPreferencesProps
    } = useTable(standings || [], {
        emptyStateSubtitle: getPalmaresError as Error,
        emptyStateTitle: "No hay palmarés."
    });

    return (
        <Table
            {...collectionProps}
            loadingText="Cargando el palmarés"
            columnDefinitions={columnDefinitions}
            items={items}
            loading={standingsIsLoading}
            wrapLines={false}
            header={<Header>Clasificación histórica</Header>}
            pagination={
                <Pagination
                    {...paginationProps}
                    ariaLabels={{
                        nextPageLabel: "Siguiente página",
                        previousPageLabel: "Página anterior",
                        pageLabel: pageNumber => `Página ${pageNumber}`
                    }}
                />
            }
            filter={
                <TextFilter
                    {...filterProps}
                    filteringPlaceholder="Buscar por texto"
                />
            }
            preferences={
                <CollectionPreferences
                    {...collectionPreferencesProps}
                    preferences={preferences}
                    onConfirm={({detail}) => setPreferences(detail)}
                />
            }
        />
    );
};

export default PalmaresTable;