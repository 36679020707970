import Flashbar, { FlashbarProps } from "@awsui/components-react/flashbar";

interface SubmitFlashbarProps {
    submitMessage: FlashbarProps.MessageDefinition | undefined;
    setSubmitMessage: (value: FlashbarProps.MessageDefinition | undefined) => void;
}

const SubmitFlashbar: React.FC<SubmitFlashbarProps> = (props: SubmitFlashbarProps) => {
    return (
        <Flashbar
            items={[{
                ...props.submitMessage,
                dismissible: true,
                dismissLabel: "Cerrar mensaje",
                onDismiss: () => props.setSubmitMessage(undefined)
            }]}
        />
    );
};

export default SubmitFlashbar;