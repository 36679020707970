import React, {useEffect} from "react";
import {firebaseConfig} from "repository/firebaseUtils";
import "firebase/compat/auth";
import firebase from "firebase/compat/app";
import {Navigate} from "react-router-dom";
import {useAuth} from "hooks/useAuth";
import Spinner from "@awsui/components-react/spinner";


// Fetch the firebase object
firebase.initializeApp(firebaseConfig);

const SignOutPage: React.FC = () => {
    const [{isSignedIn}, dispatch] = useAuth();

    isSignedIn && useEffect(() => {
        firebase.auth().signOut().then(() => {
            dispatch({
                user: undefined,
                isSignedIn: false,
                isAdmin: false,
                league: undefined
            });
        });
    }, [])


    return isSignedIn ? (
        <Spinner size="big" />
    ) : (
        <Navigate to={"/"}/>
    );
};

export default SignOutPage;
