import React, { Dispatch, SetStateAction, useState } from "react";
import Table, { TableProps } from "@awsui/components-react/table";
import Header from "@awsui/components-react/header";
import getLeagues from "api/getLeagues";
import { useQuery } from "react-query";
import useTable from "hooks/useTable";
import Pagination from "@awsui/components-react/pagination";
import TextFilter from "@awsui/components-react/text-filter";
import CollectionPreferences from "@awsui/components-react/collection-preferences";
import League from "model/League";
import JoinLeagueButton from "pages/leagues/components/JoinLeagueButton";
import { FlashbarProps } from "@awsui/components-react/flashbar";
import { useAuth } from "hooks/useAuth";
import Button from "@awsui/components-react/button";
import JoinLeagueModal from "pages/leagues/components/JoinLeagueModal";
import User from "model/User";
import Box from "@awsui/components-react/box";


const belongsToLeague = (user: User | undefined, league: League) => {
    return user?.leagues?.some(userLeague => userLeague.leagueId === league.leagueId) || false
};

const columnDefinitions = (setSubmitMessage: Dispatch<SetStateAction<FlashbarProps.MessageDefinition | undefined>>,
    setLeagueToJoin: Dispatch<SetStateAction<League | undefined>>, setShowJoinLeagueModal: Dispatch<SetStateAction<boolean>>,
    user: User | undefined): TableProps.ColumnDefinition<League>[] => [
        {
            id: "name",
            header: "Nombre",
            cell: (league: League) => league.name,
            sortingField: "name"
        },
        {
            id: "members",
            header: "Miembros",
            cell: (league: League) => league.members,
            sortingField: "members"
        },
        {
            id: "isPrivate",
            header: "Privada",
            cell: (league: League) => league.isPrivate ? "Sí" : "No",
            sortingField: "isPrivate"
        },
        {
            id: "join",
            header: "Unirse",
            cell: (league: League) => belongsToLeague(user, league) ? (
                <Box variant="span">Ya perteneces a esta liga!</Box>
            ) : (
                league.isPrivate ? (
                    <Button
                        iconAlign="left"
                        variant="normal"
                        onClick={() => {
                            setLeagueToJoin(league);
                            setShowJoinLeagueModal(true);
                        }}
                    >
                        Unirse
                    </Button>
                ) : (
                    <JoinLeagueButton
                        league={league}
                        setSubmitMessage={setSubmitMessage}
                        buttonEnabled={true}
                    />
                )
            ),
            sortingField: "join"
        }
    ];

interface LeaguesTableProps {
    setSubmitMessage: Dispatch<SetStateAction<FlashbarProps.MessageDefinition | undefined>>;
    showNewLeagueModal: boolean;
    setShowNewLeagueModal: Dispatch<SetStateAction<boolean>>;
}

const LeaguesTable: React.FC<LeaguesTableProps> = (props) => {

    const [{ user }] = useAuth();
    const [leagueToJoin, setLeagueToJoin] = useState<League>();
    const [showJoinLeagueModal, setShowJoinLeagueModal] = useState(false);

    const {
        isLoading: leaguesIsLoading,
        data: leagues,
        error: getLeaguesError
    } = useQuery<League[], Error>(`getLeagues`, () => getLeagues(user?.token));

    const {
        preferences,
        setPreferences,
        items,
        collectionProps,
        filterProps,
        paginationProps,
        collectionPreferencesProps
    } = useTable(leagues || [], {
        emptyStateSubtitle: getLeaguesError as Error,
        emptyStateTitle: "No hay ligas."
    });

    return (
        <Table
            {...collectionProps}
            loadingText="Cargando las ligas"
            columnDefinitions={columnDefinitions(props.setSubmitMessage, setLeagueToJoin, setShowJoinLeagueModal, user)}
            items={items}
            loading={leaguesIsLoading}
            wrapLines={false}
            header={<Header>Todas las ligas</Header>}
            pagination={
                <Pagination
                    {...paginationProps}
                    ariaLabels={{
                        nextPageLabel: "Siguiente página",
                        previousPageLabel: "Página anterior",
                        pageLabel: pageNumber => `Página ${pageNumber}`
                    }}
                />
            }
            filter={
                <TextFilter
                    {...filterProps}
                    filteringPlaceholder="Buscar por nombre"
                />
            }
            preferences={
                <CollectionPreferences
                    {...collectionPreferencesProps}
                    preferences={preferences}
                    onConfirm={({ detail }) => setPreferences(detail)}
                />
            }
            footer={
                <>
                    <Button
                        variant={"normal"}
                        iconName={"add-plus"}
                        onClick={() => props.setShowNewLeagueModal(!props.showNewLeagueModal)}
                    >
                        Nueva liga
                    </Button>
                    <JoinLeagueModal
                        league={leagueToJoin}
                        setLeague={setLeagueToJoin}
                        showModal={showJoinLeagueModal}
                        setShowModal={setShowJoinLeagueModal}
                    />
                </>
            }
        />
    );
};

export default LeaguesTable;