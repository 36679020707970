import SpaceBetween from "@awsui/components-react/space-between";
import React, { useState } from "react";
import NavigationBar from "components/NavigationBar";
import Container from "@awsui/components-react/container";
import Header from "@awsui/components-react/header";
import { FlashbarProps } from "@awsui/components-react/flashbar/interfaces";
import FormField from "@awsui/components-react/form-field";
import Form from "@awsui/components-react/form";
import Input from "@awsui/components-react/input";
import Button from "@awsui/components-react/button";
import AddCompetitionButton from "pages/admin/pages/add_competition/components/AddCompetitionButton";
import NewCompetition from "model/admin/NewCompetition";
import Box from "@awsui/components-react/box";
import Link from "@awsui/components-react/link";
import SubmitFlashbar from "components/SubmitFlashbar";


const AddCompetitionPage: React.FC = () => {

    const [competition, setCompetition] = useState<NewCompetition>({});
    const [buttonEnabled, setButtonEnabled] = useState(true);
    const [submitMessage, setSubmitMessage] = useState<FlashbarProps.MessageDefinition>();

    const changeCompetition = (competition: NewCompetition): void => {
        setCompetition(competition);
        setButtonEnabled(true);
    }

    return (
        <SpaceBetween size="l">
            <NavigationBar />
            <Container
                header={
                    <Box variant={"span"}>
                        <Header variant="h1">Add competition</Header>
                        <Link variant={"info"} href={"/admin"}>Back</Link>
                    </Box>
                }
            >
                <SpaceBetween size={"m"}>
                    {submitMessage && (
                        <SubmitFlashbar
                            submitMessage={submitMessage}
                            setSubmitMessage={setSubmitMessage}
                        />
                    )}
                    <form onSubmit={e => e.preventDefault()}>
                        <Form
                            actions={
                                <SpaceBetween direction="horizontal" size="xs">
                                    <Button formAction="none" variant="link" href="/">
                                        Cancelar
                                    </Button>
                                    <AddCompetitionButton
                                        competition={competition}
                                        setCompetition={setCompetition}
                                        buttonEnabled={buttonEnabled}
                                        setButtonEnabled={setButtonEnabled}
                                        setSubmitMessage={setSubmitMessage}
                                    />
                                </SpaceBetween>
                            }
                        >
                            <SpaceBetween direction="vertical" size="l">
                                <FormField key={"name"} label={<Header variant="h2">Name</Header>}>
                                    <Input
                                        inputMode="text"
                                        onChange={({ detail }) => changeCompetition({
                                            ...competition,
                                            name: detail.value
                                        })}
                                        value={competition?.name || ""}
                                    />
                                </FormField>
                                <FormField key={"year"} label={<Header variant="h2">Year</Header>}>
                                    <Input
                                        inputMode="numeric"
                                        onChange={({ detail }) => changeCompetition({
                                            ...competition,
                                            year: parseInt(detail.value)
                                        })}
                                        value={competition?.year?.toString() || ""}
                                    />
                                </FormField>
                                <FormField key={"code"} label={<Header variant="h2">Code</Header>}>
                                    <Input
                                        inputMode="text"
                                        onChange={({ detail }) => changeCompetition({
                                            ...competition,
                                            code: detail.value
                                        })}
                                        value={competition?.code || ""}
                                    />
                                </FormField>
                                <FormField key={"logoUrl"} label={<Header variant="h2">Logo URL</Header>}>
                                    <Input
                                        inputMode="url"
                                        onChange={({ detail }) => changeCompetition({
                                            ...competition,
                                            logoUrl: detail.value
                                        })}
                                        value={competition?.logoUrl || ""}
                                    />
                                </FormField>
                                <FormField key={"numberOfStages"} label={<Header variant="h2">Number of stages</Header>}>
                                    <Input
                                        inputMode="numeric"
                                        onChange={({ detail }) => changeCompetition({
                                            ...competition,
                                            numberOfStages: parseInt(detail.value)
                                        })}
                                        value={competition?.numberOfStages?.toString() || ""}
                                    />
                                </FormField>
                                <FormField key={"shortName"} label={<Header variant="h2">Short name</Header>}>
                                    <Input
                                        inputMode="text"
                                        onChange={({ detail }) => changeCompetition({
                                            ...competition,
                                            shortName: detail.value
                                        })}
                                        value={competition?.shortName || ""}
                                    />
                                </FormField>
                            </SpaceBetween>
                        </Form>
                    </form>
                </SpaceBetween>
            </Container>
        </SpaceBetween>
    )
};

export default AddCompetitionPage;
