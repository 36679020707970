import React from "react";
import {CompetitionState} from "../../model/CompetitionState";
import FirstPositionIcon from "./FirstPositionIcon";
import SecondPositionIcon from "./SecondPositionIcon";
import ThirdPositionIcon from "./ThirdPositionIcon";
import LastPositionIcon from "./LastPositionIcon";
import Competition from "../../model/Competition";


interface PositionIconProps {
    competition?: Competition;
    position: number;
    length: number | undefined;
}

const PositionIcon: React.FC<PositionIconProps> = (props) => {
    if (props.competition?.state === CompetitionState.STARTED || props.competition?.state === CompetitionState.NOT_STARTED) {
        return null;
    }
    switch (props.position) {
        case 1:
            return <FirstPositionIcon/>;
        case 2:
            return <SecondPositionIcon/>;
        case 3:
            return <ThirdPositionIcon/>;
        default:
            return props.position == props.length ? <LastPositionIcon/> : null;
    }
};

export default PositionIcon;
