import React, { SetStateAction, Dispatch } from "react";
import Button from "@awsui/components-react/button";
import { useMutation } from "react-query";
import Competition from "model/Competition";
import { FlashbarProps } from "@awsui/components-react/flashbar/interfaces";
import addPoints from "api/addPoints";
import Box from "@awsui/components-react/box";
import {useAuth} from "hooks/useAuth";


interface AddPointsButtonProps {
    competition: Competition;
    stage: number;
    buttonEnabled: boolean;
    isTTT: boolean;
    setButtonEnabled: Dispatch<SetStateAction<boolean>>;
    setSubmitMessage: Dispatch<SetStateAction<FlashbarProps.MessageDefinition | undefined>>;
}

const AddPointsButton: React.FC<AddPointsButtonProps> = (props) => {

    const competition = props.competition;
    const stage = props.stage;
    const isTTT = props.isTTT;

    const [{user}] = useAuth();

    const {
        mutate: addPointsMutation,
        isLoading: addPointsIsLoading
    } = useMutation(() => addPoints(competition, stage, isTTT, user?.token), {
        onSuccess: () => {
            props.setSubmitMessage({
                header: "Points added correctly",
                type: "success",
                content: "",
            })
        },
        onError: () => {
            props.setSubmitMessage({
                header: "Error when adding points",
                type: "error",
                content: "Try to refresh the page.",
            })
        },
        onSettled: () => {
            props.setButtonEnabled(false);
        }
    }
    );

    const submitPoints = () => {
        addPointsMutation();
    }

    return (
        <Box float="right">
            <Button
                disabled={!props.buttonEnabled}
                iconAlign="left"
                loading={addPointsIsLoading}
                variant="primary"
                onClick={submitPoints}
            >
                Submit
            </Button>
        </Box>
    );
};

export default AddPointsButton;
