import { useAuth } from "hooks/useAuth";
import React from "react";
import { Navigate } from "react-router-dom";
import ProtectedRoute from "components/ProtectedRoute";


/* eslint-disable  @typescript-eslint/no-explicit-any */
const AdminRoute: React.FC<any> = ({ children }) => {

    const [{ isAdmin }] = useAuth();

    return isAdmin ? (
        <ProtectedRoute>
            {children}
        </ProtectedRoute>
    ) : (
        <Navigate to={"/"} />
    );
}

export default AdminRoute;
