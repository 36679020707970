import React, { SetStateAction, Dispatch } from "react";
import Button from "@awsui/components-react/button";
import { useMutation } from "react-query";
import Competition from "model/Competition";
import { FlashbarProps } from "@awsui/components-react/flashbar/interfaces";
import addQuestion from "api/addQuestion";
import Box from "@awsui/components-react/box";
import NewQuestion from "model/admin/NewQuestion";
import {useAuth} from "hooks/useAuth";


interface AddQuestionButtonProps {
    competition: Competition | undefined;
    question: NewQuestion;
    setQuestion: Dispatch<SetStateAction<NewQuestion>>;
    buttonEnabled: boolean;
    setButtonEnabled: Dispatch<SetStateAction<boolean>>;
    setSubmitMessage: Dispatch<SetStateAction<FlashbarProps.MessageDefinition | undefined>>;
}

const AddQuestionButton: React.FC<AddQuestionButtonProps> = (props) => {

    const competition = props.competition;
    const question = props.question;

    const [{user}] = useAuth();

    const {
        mutate: addQuestionMutation,
        isLoading: addQuestionIsLoading
    } = useMutation((question: NewQuestion) => addQuestion(competition || {}, question, user?.token), {
        onSuccess: () => {
            props.setSubmitMessage({
                header: "Question added correctly",
                type: "success",
                content: "",
            })
        },
        onError: () => {
            props.setSubmitMessage({
                header: "Error when adding question",
                type: "error",
                content: "Try to refresh the page.",
            })
        },
        onSettled: () => {
            props.setButtonEnabled(false);
            props.setQuestion({});
        }
    });

    const submitQuestion = () => {
        question && competition && addQuestionMutation(question);
    }

    return (
        <Box float="right">
            <Button
                disabled={!props.buttonEnabled || (question === {} || !question.answers)}
                iconAlign="left"
                loading={addQuestionIsLoading}
                variant="primary"
                onClick={submitQuestion}
            >
                Submit
            </Button>
        </Box>
    );
};

export default AddQuestionButton;
