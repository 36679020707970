import {fetchApiClient} from "common/fetchApiClient";
import {API_URL} from "common/const";
import TeamAndSynonyms from "model/TeamAndSynonyms";
import TeamSynonym from "model/TeamSynonym";
import UnauthorizedError from "api/errors/UnauthorizedError";
import { AxiosError } from "axios";


const addTeamSynonyms = async (synonyms: TeamSynonym[], token: string | undefined): Promise<void> => {
    const apiClient = fetchApiClient(token);

    await apiClient.put<TeamAndSynonyms[]>(`${API_URL}/team_synonyms`, {
        synonyms: synonyms
    }).catch((error: AxiosError) => {
        console.log(`Error setting rider synonyms`, synonyms, error);
        if (!!error.response && error.response.status === 403) {
            throw new UnauthorizedError();
        }
        throw error;
    });
}

export default addTeamSynonyms;
