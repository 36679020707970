import React, { useState } from "react";
import SpaceBetween from "@awsui/components-react/space-between";
import NavigationBar from "components/NavigationBar";
import Container from "@awsui/components-react/container";
import Header from "@awsui/components-react/header";
import LineupsTable from "pages/lineup/components/LineupsTable";
import Competition from "model/Competition";
import CompetitionsSelect from "components/CompetitionsSelect";
import NoOpenCompetitionsFlashbar from "components/NoOpenCompetitionsFlashbar";
import { CompetitionState } from "model/CompetitionState";
import SubmitFlashbar from "components/SubmitFlashbar";
import { FlashbarProps } from "@awsui/components-react";


const LineupPage: React.FC = () => {

    const [selectedCompetition, setSelectedCompetition] = useState<Competition>();
    const [submitMessage, setSubmitMessage] = useState<FlashbarProps.MessageDefinition>();

    return (
        <SpaceBetween size={"l"}>
            <NavigationBar />
            <Container
                variant={"default"}
                header={
                    <Header variant={"h2"}>
                        Alineación
                    </Header>
                }
            >
                <SpaceBetween size={"l"}>
                    <CompetitionsSelect
                        selectedCompetition={selectedCompetition}
                        setSelectedCompetition={setSelectedCompetition}
                        disabledStates={[CompetitionState.STARTED, CompetitionState.FINISHED]}
                    />
                    {!!selectedCompetition ? (
                        <SpaceBetween direction="vertical" size="l">
                            {submitMessage && (
                                <SubmitFlashbar
                                    submitMessage={submitMessage}
                                    setSubmitMessage={setSubmitMessage}
                                />
                            )}
                            <LineupsTable
                                competition={selectedCompetition}
                                setSubmitMessage={setSubmitMessage}
                            />
                        </SpaceBetween>
                    ) : (
                        <NoOpenCompetitionsFlashbar />
                    )}
                </SpaceBetween>
            </Container>
        </SpaceBetween>
    )
};

export default LineupPage;
