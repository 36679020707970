import { AxiosError } from "axios";
import {API_URL} from "common/const";
import {fetchApiClient} from "common/fetchApiClient";
import EditedRider from "model/admin/EditedRider";
import UnauthorizedError from "api/errors/UnauthorizedError";


const setRidersCountry = async (editedRiders: EditedRider[], token: string | undefined): Promise<void> => {
    const apiClient = fetchApiClient(token);

    await apiClient.post(`${API_URL}/riders_country`, {
        riders: editedRiders.map(rider => ({
            riderId: rider.riderId,
            country: rider.country
        }))
    }).catch((error: AxiosError) => {
        console.log(`Error setting riders' country for riderIds`, editedRiders.map(rider => rider.riderId), error);
        if (!!error.response && error.response.status === 403) {
            throw new UnauthorizedError();
        }
        throw error;
    });
}

export default setRidersCountry;
