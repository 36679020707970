import React, { SetStateAction, Dispatch } from "react";
import Button from "@awsui/components-react/button";
import { useMutation } from "react-query";
import Competition from "model/Competition";
import { FlashbarProps } from "@awsui/components-react/flashbar/interfaces";
import editCompetition from "api/editCompetition";
import Box from "@awsui/components-react/box";
import {useAuth} from "hooks/useAuth";


interface EditCompetitionButtonProps {
    competition: Competition | undefined;
    setCompetition: Dispatch<SetStateAction<Competition | undefined>>;
    buttonEnabled: boolean;
    setButtonEnabled: Dispatch<SetStateAction<boolean>>;
    setSubmitMessage: Dispatch<SetStateAction<FlashbarProps.MessageDefinition | undefined>>;
}

const EditCompetitionButton: React.FC<EditCompetitionButtonProps> = (props) => {

    const competition = props.competition;

    const [{user}] = useAuth();

    const {
        mutate: editCompetitionMutation,
        isLoading: editCompetitionIsLoading
    } = useMutation((competition: Competition) => editCompetition(competition, user?.token), {
        onSuccess: () => {
            props.setSubmitMessage({
                header: "Competition edited correctly",
                type: "success",
                content: "",
            })
        },
        onError: () => {
            props.setSubmitMessage({
                header: "Error when editing competition",
                type: "error",
                content: "Try to refresh the page.",
            })
        },
        onSettled: () => {
            props.setButtonEnabled(false);
        }
    }
    );

    const submitEditCompetition = () => {
        competition && editCompetitionMutation(competition);
    }

    return (
        <Box float="right">
            <Button
                disabled={!props.buttonEnabled || (competition === {})}
                iconAlign="left"
                loading={editCompetitionIsLoading}
                variant="primary"
                onClick={submitEditCompetition}
            >
                Submit
            </Button>
        </Box>
    );
};

export default EditCompetitionButton;
