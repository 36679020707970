import React from "react";
import SpaceBetween from "@awsui/components-react/space-between";
import Button from "@awsui/components-react/button";
import NavigationBar from "components/NavigationBar";
import Container from "@awsui/components-react/container";
import Header from "@awsui/components-react/header";

const NotFoundPage: React.FC = () => (
    <SpaceBetween size="l">
        <NavigationBar />
        <Container
            variant="default"
            header={
                <Header variant="h2">Página no encontrada</Header>
            }
        >
            <Button
                variant={"primary"}
                href={"/"}
            >
                Volver a inicio
            </Button>
        </Container>
    </SpaceBetween>
);

export default NotFoundPage;
