import React, {ReactNode} from "react";
import Table from "@awsui/components-react/table";
import Box from "@awsui/components-react/box";
import Header from "@awsui/components-react/header";
import UserAnswer from "model/UserAnswer";
import Question from "model/Question";
import Answer from "model/Answer";
import Icon from "@awsui/components-react/icon";
import {QuestionState} from "model/QuestionState";
import PossibleAnswersPopover from "components/PossibleAnswersPopover";


interface AllAnswersTableProps {
    username: string;
    questions: Question[];
    answers: UserAnswer[];
    points: number;
}

const AllAnswersTable: React.FC<AllAnswersTableProps> = (props) => {

    const fetchQuestion = (questionId: number): Question | undefined => {
        return props.questions.filter(question => question.questionId === questionId).find(question => question !== undefined);
    }
    const fetchAnswer = (questionId: number, answerId: number): Answer | undefined => {
        return fetchQuestion(questionId)?.answers.filter(answer => answer.answerId === answerId).find(answer => answer);
    }
    const fetchAnswerIcon = (questionId: number, answerId: number): ReactNode => {
        const question = fetchQuestion(questionId);
        return question?.state === QuestionState.ANSWERED
            ? question.answerId == answerId
                ? (<Icon name="status-positive"/>)
                : (<Icon name="status-negative"/>)
            : (<Icon name="status-in-progress"/>);
    }

    return (
        <Table
            columnDefinitions={[
                {
                    id: "question",
                    header: "Pregunta",
                    cell: (answer: UserAnswer) => <Box variant="span">{fetchQuestion(answer.questionId)?.text}</Box>,
                    sortingField: "question"
                },
                {
                    id: "answer",
                    header: "Respuesta",
                    cell: (answer: UserAnswer) => {
                        const question = fetchQuestion(answer.questionId);
                        const userAnswerText = fetchAnswer(answer.questionId, answer.answerId)?.text;
                        return question && userAnswerText && (
                            <PossibleAnswersPopover answers={question.answers} userAnswerId={answer.answerId}
                                                    userAnswerText={userAnswerText} correctAnswerId={question.answerId}/>
                        );
                    },
                    sortingField: "answer"
                },
                {
                    id: "correct",
                    header: "Correcto",
                    cell: (answer: UserAnswer) => fetchAnswerIcon(answer.questionId, answer.answerId),
                    sortingField: "points"
                }
            ]}
            items={props.answers}
            loadingText="Cargando preguntas"
            sortingDisabled
            variant="full-page"
            empty={
                <Box textAlign="center" color="inherit">
                    <Box
                        padding={{bottom: "s"}}
                        variant="p"
                        color="inherit"
                    >
                        No hay respuestas.
                    </Box>
                </Box>
            }
            header={<Header variant="h2">{props.username}</Header>}
            footer={<Box variant="h4" textAlign="right" color="text-status-info">{props.points} puntos</Box>}
        />
    )
};

export default AllAnswersTable;