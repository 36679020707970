import React from "react";
import Icon from "@awsui/components-react/icon";

const ThirdPositionIcon: React.FC = () => {
    return (
        <Icon
            url="https://img.icons8.com/color/96/olympic-medal-bronze.png"
        />
    );
};

export default ThirdPositionIcon;
