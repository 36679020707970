import { Context, createContext, Dispatch, useContext } from "react";
import User from "model/User";
import useLocalStorageReducer from "hooks/useLocalStorageReducer";
import League from "model/League";


interface AuthState {
    user?: User;
    isSignedIn: boolean;
    isAdmin: boolean;
    league?: League;
}

const defaultValue: AuthState = {
    isSignedIn: false,
    isAdmin: false
}

// Create a context
const AuthStateContext: Context<AuthState> = createContext<AuthState>(defaultValue);
const AuthDispatchContext: Context<Dispatch<AuthState>> = createContext<Dispatch<AuthState>>(undefined as unknown as Dispatch<AuthState>);

const AuthProvider: React.FC = ({ children }) => {
    const [state, dispatch] = useLocalStorageReducer(
        "auth",
        defaultValue,
        authStateReducer
    );

    return (
        <AuthStateContext.Provider value={state}>
            <AuthDispatchContext.Provider value={dispatch}>
                {children}
            </AuthDispatchContext.Provider>
        </AuthStateContext.Provider>
    );
};

const authStateReducer = (state: AuthState, action: AuthState) => {
    return {
        user: action.user || state.user,
        isSignedIn: action.isSignedIn,
        isAdmin: action.isAdmin,
        league: action.league
            ? action.league
            : action.user?.leagues?.find(league => league),
    };
}

const useAuth = (): [AuthState, Dispatch<AuthState>] => {
    return [useAuthState(), useAuthDispatch()];
}

const useAuthState = (): AuthState => {
    const context = useContext(AuthStateContext);

    if (context === undefined) {
        throw new Error(
            "useAuthState must be used within an AuthProvider"
        );
    }

    return context;
}

const useAuthDispatch = (): Dispatch<AuthState> => {
    const context = useContext(AuthDispatchContext);

    if (context === undefined) {
        throw new Error(
            "useAuthDispatch must be used within an AuthProvider"
        );
    }

    return context;
}

export { AuthProvider, useAuth };
