import React from "react";
import Box from "@awsui/components-react/box";
import Rider from "model/Rider";
import {formatNumber} from "common/formatNumber";


interface LineupsValueProps {
    selectedRiders: Rider[];
}

const LineupsValue: React.FC<LineupsValueProps> = (props) => {

    const ridersValue: number = props.selectedRiders.length
        ? props.selectedRiders
            .map((item: { value: number; }) => item.value)
            .reduce((previousValue: number, currentValue: number) => previousValue + currentValue)
        : 0;
    const moneyLeft: number = formatNumber(150 - ridersValue, 1);

    return (
        <Box
            textAlign={"left"}
            variant="span"
            fontSize="heading-xs"
            color={ridersValue > 150.05 ? "text-status-error" : "text-body-secondary"}
            fontWeight="bold"
        >
            {`${formatNumber(ridersValue, 1)}/150 mls. (te ${moneyLeft > 0 ? "quedan" : "sobran"} ${Math.abs(moneyLeft)} mls.)`}
        </Box>
    );
};

export default LineupsValue;
