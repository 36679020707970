import React, {useState} from "react";
import {Navigate} from "react-router-dom";
import {ErrorBoundary} from 'react-error-boundary'
import Flashbar from "@awsui/components-react/flashbar";
import UnauthorizedError from "api/errors/UnauthorizedError";
import { useAuth } from "hooks/useAuth";


const refreshPage = () => window.location.reload();

/* eslint-disable  @typescript-eslint/no-explicit-any */
const UnauthorizedContainer: React.FC<any> = ({children}) => {

    const [isUnauthorized, setIsUnauthorized] = useState(false);
    const [{isSignedIn}] = useAuth();

    function UnauthorizedFallback({error}: any): JSX.Element {
        if (!isUnauthorized && !isSignedIn && error instanceof UnauthorizedError) {
            refreshPage();
        }
        return (
            <Flashbar items={[{
                type: "info",
                header: "Ha habido un error. Pruebe a recargar la página",
                content: "",
                dismissible: false
            }]}/>
        );
    }

    const handleError = (error: Error): void => {
        if (error instanceof UnauthorizedError && isSignedIn) {
            setIsUnauthorized(true);
        }
    }

    if (isUnauthorized && isSignedIn) {
        return (        
            <Navigate to={"/signout"}/>
        );
    }

    return (
        <ErrorBoundary FallbackComponent={UnauthorizedFallback} onError={handleError}>
            {children}
        </ErrorBoundary>
    );
}

export default UnauthorizedContainer;
