import React, { SetStateAction, Dispatch } from "react";
import Button from "@awsui/components-react/button";
import { useMutation } from "react-query";
import Competition from "model/Competition";
import { FlashbarProps } from "@awsui/components-react/flashbar/interfaces";
import recalculatePoints from "api/recalculatePoints";
import Box from "@awsui/components-react/box";
import {useAuth} from "hooks/useAuth";


interface RecalculatePointsButtonProps {
    competition: Competition;
    buttonEnabled: boolean;
    setButtonEnabled: Dispatch<SetStateAction<boolean>>;
    setSubmitMessage: Dispatch<SetStateAction<FlashbarProps.MessageDefinition | undefined>>;
}

const RecalculatePointsButton: React.FC<RecalculatePointsButtonProps> = (props) => {

    const competition = props.competition;

    const [{user}] = useAuth();

    const {
        mutate: recalculatePointsMutation,
        isLoading: recalculatePointsIsLoading
    } = useMutation(() => recalculatePoints(competition, user?.token), {
        onSuccess: () => {
            props.setSubmitMessage({
                header: "Points recalculated correctly",
                type: "success",
                content: "",
            })
        },
        onError: () => {
            props.setSubmitMessage({
                header: "Error when recalculating points",
                type: "error",
                content: "Try to refresh the page.",
            })
        },
        onSettled: () => {
            props.setButtonEnabled(false);
        }
    }
    );

    const submitPoints = () => {
        recalculatePointsMutation();
    }

    return (
        <Box float="right">
            <Button
                disabled={!props.buttonEnabled}
                iconAlign="left"
                loading={recalculatePointsIsLoading}
                variant="primary"
                onClick={submitPoints}
            >
                Submit
            </Button>
        </Box>
    );
};

export default RecalculatePointsButton;
