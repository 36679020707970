import Competition from "model/Competition";
import {API_URL} from "common/const";
import {fetchApiClient} from "common/fetchApiClient";
import { AxiosError } from "axios";
import UnauthorizedError from "api/errors/UnauthorizedError";


const addPoints = async (competition: Competition, stage: number, isTTT: boolean, token: string | undefined): Promise<void> => {
    const apiClient = fetchApiClient(token);

    await apiClient.put(`${API_URL}/points/${competition.code}/${stage}`, {
        isTTT: isTTT
    }).catch((error: AxiosError) => {
        console.log(`Error adding points for Competition(${competition.code}) and Stage(${stage})`, error);
        if (!!error.response && error.response.status === 403) {
            throw new UnauthorizedError();
        }
        throw error;
    })
}

export default addPoints;
