import React, {Dispatch, SetStateAction, useState} from "react";
import Select, {SelectProps} from "@awsui/components-react/select";
import {OptionDefinition} from "@awsui/components-react/internal/components/option/interfaces";


interface StageSelectProps {
    setStage: Dispatch<SetStateAction<number>>;
    length: number | undefined;
    lastStage?: number | undefined;
}

const generalStageOption: SelectProps.Option = {
    value: "0",
    label: "General"
};
const stageOptions = (length: number): SelectProps.Option[] =>
    [generalStageOption].concat([...Array(length).keys()].map(value => ({
        value: `${value + 1}`,
        label: `${value + 1}`
    })));

const StageSelect: React.FC<StageSelectProps> = (props) => {

    const [selectedOption, setSelectedOption] = useState(props.lastStage !== undefined
        ? {
            value: `${props.lastStage + 1}`,
            label: `${props.lastStage + 1}`
        } : generalStageOption);

    const changeStage = (selected: OptionDefinition) => {
        setSelectedOption(selected);
        props.setStage(parseInt(selected.value || "0"));
    }

    return (
        <Select
            name="Etapa"
            selectedOption={selectedOption}
            onChange={({detail}) => (
                changeStage(detail.selectedOption)
            )}
            options={stageOptions(props.length || 21)}
            selectedAriaLabel="Selected"
        />
    );
}

export default StageSelect;
