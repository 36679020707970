import React, { Dispatch, SetStateAction, useState } from "react";
import Select from "@awsui/components-react/select";
import Competition from "model/Competition";
import { useQuery } from "react-query";
import getCompetitions from "api/getCompetitions";
import Flashbar from "@awsui/components-react/flashbar";
import Spinner from "@awsui/components-react/spinner";
import { OptionDefinition } from "@awsui/components-react/internal/components/option/interfaces";
import { OptionGroup } from "@awsui/components-react/internal/components/option/interfaces";
import { CompetitionState } from "model/CompetitionState";
import {useAuth} from "hooks/useAuth";


interface CompetitionsSelectProps {
    selectedCompetition: Competition | undefined;
    setSelectedCompetition: Dispatch<SetStateAction<Competition | undefined>>;
    disabledStates: CompetitionState[]; 
}

const arrangeCompetitions = (competitions: Competition[] | undefined): Map<number, Competition[]> => {
    const arranged: Map<number, Competition[]> = new Map<number, Competition[]>();

    competitions?.map(competition => {
        const result = arranged.get(competition.year || 0);
        if (result) {
            result.push(competition);
        } else {
            arranged.set(competition.year || 0, [competition]);
        }
    });

    arranged.forEach((value, key) => {
        arranged.set(key, value.sort((a, b) => (a.name || "") > (b.name || "") ? 1 : -1));
    })

    return arranged;
}

const arrangeCompetitionItemsByYear = (arrangedCompetitions: Map<number, Competition[]>, disabledStates: CompetitionState[]): OptionGroup[] => {
    const items: OptionGroup[] = [];
    arrangedCompetitions.forEach((comps: Competition[], year: number) => {
        items.push({
            label: `${year}`,
            options: comps.map(comp => ({
                label: comp.name, 
                value: comp.code,
                disabled: disabledStates.includes(comp.state || CompetitionState.FINISHED)
            })),
            disabled: false
        });
    });

    return items;
}

const CompetitionsSelect: React.FC<CompetitionsSelectProps> = (props) => {

    const [{user}] = useAuth();
    const [selectedOption, setSelectedOption] = useState({});

    const {
        isLoading: competitionsIsLoading,
        data: competitions,
        error: getCompetitionsError
    } = useQuery<Competition[], Error>("getCompetitions", () => getCompetitions(user?.token), {
        onSuccess: (comps) => {
            const initialSelectedComp = comps?.filter(competition => (
                !props.disabledStates.includes(competition.state || CompetitionState.FINISHED)
            )).find(element => element);
            initialSelectedComp && changeCompetition({
                label: initialSelectedComp.name,
                value: initialSelectedComp.code
            }, comps);
        }
    });

    const changeCompetition = (selected: OptionDefinition, comps: Competition[]) => {
        setSelectedOption(selected);
        const selectedComp: Competition = comps?.filter(competition => (
            competition.code === selected.value
        ))[0];
        props.setSelectedCompetition(selectedComp);
    }

    if (getCompetitionsError) {
        return (
            <Flashbar
                items={[{
                    header: "Error al cargar las competiciones",
                    type: "error",
                    content: "Prueba a recargar la página.",
                    dismissible: false,
                }]}
            />
        );
    }

    if (competitionsIsLoading || selectedOption === undefined) {
        return (
            <Spinner size="big" />
        );
    }

    return (
        <Select
            selectedOption={selectedOption}
            onChange={({ detail }) => (
                changeCompetition(detail.selectedOption, competitions || [])
            )}
            options={arrangeCompetitionItemsByYear(arrangeCompetitions(competitions), props.disabledStates)}
            selectedAriaLabel="Selected"
        />
    );
}

export default CompetitionsSelect;
