import AllLineupsItem from "model/AllLineupsItem";
import Competition from "model/Competition";
import League from "model/League";
import {API_URL} from "common/const";
import {fetchApiClient} from "common/fetchApiClient";
import { AxiosError } from "axios";
import UnauthorizedError from "api/errors/UnauthorizedError";


const getLineups = async (competition: Competition, stage: number, league: League | undefined, token: string | undefined): Promise<AllLineupsItem[]> => {
    const apiClient = fetchApiClient(token);

    const url = `${API_URL}/lineups/${competition.code}/${league?.leagueId}`.concat(stage != -1 ? `/${stage}` : "");

    return await apiClient.get<AllLineupsItem[]>(url)
        .then(response => {
            return response.data;
        }).catch((error: AxiosError) => {
            console.log(`Error fetching lineups for Competition(${competition.code}), League(${league?.name}) and Stage(${stage})`, error);
            if (!!error.response && error.response.status === 403) {
                throw new UnauthorizedError();
            }
            throw error;
        });
}

export default getLineups;
