import React from "react";
import Table from "@awsui/components-react/table";
import Box from "@awsui/components-react/box";
import Header from "@awsui/components-react/header";


interface PointsItem {
    position: number;
    points: number;
}

interface PointsTableProps {
    title: string;
    points: number[];
}

const PointsTable: React.FC<PointsTableProps> = (props) => (
    <Table
        columnDefinitions={[
            {
                id: "position",
                header: "Posición",
                cell: (item: PointsItem) => item.position || "-",
                sortingField: "position"
            },
            {
                id: "points",
                header: "Puntos",
                cell: (item: PointsItem) => item.points || "-",
                sortingField: "position"
            }
        ]}
        items={props.points.map((value: number, index: number) => {
            return {
                position: index + 1,
                points: value
            };
        })}
        loadingText="Cargando recursos"
        sortingDisabled
        variant="full-page"
        empty={
            <Box textAlign="center" color="inherit">
                <Box
                    padding={{ bottom: "s" }}
                    variant="p"
                    color="inherit"
                >
                    No hay puntos.
                </Box>
            </Box>
        }
        header={<Header>{props.title}</Header>}
    />
);

export default PointsTable;
