import React from "react";
import Icon from "@awsui/components-react/icon";

const LastPositionIcon: React.FC = () => {
    return (
        <Icon
            url="https://img.icons8.com/emoji/96/black-heart.png"
        />
    );
};

export default LastPositionIcon;
