import Competition from "model/Competition";
import League from "model/League";
import Rider from "model/Rider";
import {fetchApiClient} from "common/fetchApiClient";
import {API_URL} from "common/const";
import { AxiosError } from "axios";
import UnauthorizedError from "api/errors/UnauthorizedError";


const getRiders = async (competition: Competition, league: League, token: string | undefined): Promise<Rider[]> => {
    const apiClient = fetchApiClient(token);

    return await apiClient.get<Rider[]>(`${API_URL}/riders/${competition.code}/${league.leagueId}`)
        .then(response => {
            return response.data;
        }).catch((error: AxiosError) => {
            console.log(`Error fetching riders for Competition(${competition.code}) and League(${league.name})`, error);
            if (!!error.response && error.response.status === 403) {
                throw new UnauthorizedError();
            }
            throw error;
        });
}

export default getRiders;
