import React from "react";
import SpaceBetween from "@awsui/components-react/space-between";
import AllAnswersTable from "pages/standings/components/AllAnswersTable";
import Competition from "model/Competition";
import { useQuery } from "react-query";
import { Flashbar, Spinner } from "@awsui/components-react";
import getAnswers from "api/getAnswers";
import { useAuth } from "hooks/useAuth";
import { Navigate } from "react-router-dom";
import AllAnswers from "model/AllAnswers";


interface AllAnswersComponentProps {
    competition: Competition;
}

const AllAnswersComponent: React.FC<AllAnswersComponentProps> = (props) => {

    const competition = props.competition;

    const [{ league, user }] = useAuth();

    if (!league) {
        return (<Navigate to={"/"} />);
    }

    const {
        isLoading: answersIsLoading,
        data: answers,
        error: getAnswersError
    } = useQuery<AllAnswers, Error>(`getAnswers-${competition.code}`, () => getAnswers(competition, league, user?.token));

    if (answersIsLoading) {
        return (
            <Spinner size="big" variant="normal" />
        );
    }

    if (getAnswersError) {
        return (
            <Flashbar
                items={[{
                    header: "Error al cargar las preguntas",
                    type: "error",
                    content: "Prueba a recargar la página.",
                    dismissible: false,
                }]}
            />
        );
    }

    const renderedAnswerTables = answers?.answers.map(answer => {
        return (
            <AllAnswersTable
                key={answer.username || ""}
                username={answer.username || ""}
                questions={answers.questions}
                answers={answer.userAnswers}
                points={answer.points}
            />
        );
    });

    return (
        <SpaceBetween size="m" direction="horizontal">
            {renderedAnswerTables}
        </SpaceBetween>
    )
};

export default AllAnswersComponent;
