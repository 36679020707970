import React, { SetStateAction, Dispatch } from "react";
import Button from "@awsui/components-react/button";
import { useMutation } from "react-query";
import addMembership from "api/addMembership";
import { FlashbarProps } from "@awsui/components-react/flashbar/interfaces";
import { useAuth } from "hooks/useAuth";
import League from "model/League";
import WrongPasswordError from "api/errors/WrongPasswordError";
import AlreadyInLeagueError from "api/errors/AlreadyInLeagueError";


interface JoinLeagueButtonProps {
    league: League;
    setSubmitMessage: Dispatch<SetStateAction<FlashbarProps.MessageDefinition | undefined>>;
    buttonEnabled: boolean;
    setButtonEnabled?: Dispatch<SetStateAction<boolean>>;
}

const JoinLeagueButton: React.FC<JoinLeagueButtonProps> = (props) => {

    const leagueToJoin = props.league;

    const [{ user, isSignedIn, isAdmin }, dispatch] = useAuth();

    const {
        isLoading: addMembershipIsLoading,
        mutate: callAddMembership
    } = useMutation((league: League) => addMembership(league, user?.token), {
        onSuccess: () => {
            props.setSubmitMessage({
                header: `¡Ahora perteneces a ${leagueToJoin.name}!`,
                type: "success",
                content: "",
            });
            user?.leagues && dispatch({
                isSignedIn: isSignedIn,
                isAdmin: isAdmin,
                user: {
                    ...user,
                    leagues: [
                        ...user?.leagues,
                        {
                            ...leagueToJoin,
                            members: leagueToJoin.members + 1
                        }
                    ]
                }
            });
        },
        onError: (error: Error) => {
            if (error instanceof WrongPasswordError) {
                props.setSubmitMessage({
                    header: `Error al unirte a ${leagueToJoin.name}`,
                    type: "error",
                    content: "Contraseña incorrecta",
                });
            } else if (error instanceof AlreadyInLeagueError) {
                props.setSubmitMessage({
                    header: `Error al unirte a ${leagueToJoin.name}`,
                    type: "error",
                    content: `Ya eres parte de ${leagueToJoin.name}`,
                });
            } else {
                props.setSubmitMessage({
                    header: `Error al unirte a ${leagueToJoin.name}`,
                    type: "error",
                    content: "Prueba a intentarlo de nuevo",
                });
            }
        },
        onSettled: () => {
            props.setButtonEnabled && props.setButtonEnabled(false);
        }
    });

    const joinLeague = () => {
        props.setSubmitMessage(undefined);
        callAddMembership({
            ...leagueToJoin,
            password: btoa(leagueToJoin.password || "")
        });
    }

    return (
        <Button
            disabled={!props.buttonEnabled}
            iconAlign="left"
            loading={addMembershipIsLoading}
            variant="primary"
            onClick={joinLeague}
        >
            Unirse
        </Button>
    );
};

export default JoinLeagueButton;
