import Rider from "model/Rider";


const parseRiders = (ridersText: string): Rider[] => {
    const separatedRiders = ridersText.split("\t\t").slice(1).map(riderTxtItem =>
        riderTxtItem.split("\r\n")[0].split("\t")).map(text => text.map(r => r.split("\n")[0]));

    return separatedRiders.map(rider => {
        const split_name = rider[0].split(", ");
        return {
            name: `${split_name[1]} ${split_name[0].toUpperCase()}`,
            value: parseFloat(rider[1].split(" ")[0]),
            team: rider[2],
        };
    });
}

export default parseRiders;
