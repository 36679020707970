import Competition from "model/Competition";
import StatsItem from "model/StatsItem";
import {API_URL} from "common/const";
import {fetchApiClient} from "common/fetchApiClient";
import { AxiosError } from "axios";
import UnauthorizedError from "api/errors/UnauthorizedError";


const getStats = async (competition: Competition, token: string | undefined): Promise<StatsItem[]> => {
    const apiClient = fetchApiClient(token);

    return await apiClient.get<StatsItem[]>(`${API_URL}/stats/${competition.code}`)
        .then(response => {
            return response.data;
        }).catch((error: AxiosError) => {
            console.log(`Error fetching stats for Competition(${competition.code})`, error);
            if (!!error.response && error.response.status === 403) {
                throw new UnauthorizedError();
            }
            throw error;
        });
}

export default getStats;
