import {useAuth} from "hooks/useAuth";
import React from "react";
import {Navigate} from "react-router-dom";


/* eslint-disable  @typescript-eslint/no-explicit-any */
const LeagueRoute: React.FC<any> = ({children}) => {

    const [{league}] = useAuth();

    return (league ? children : <Navigate to={"/leagues"}/>);
}

export default LeagueRoute;
