import React, {ReactNode, useState} from "react";
import SpaceBetween from "@awsui/components-react/space-between";
import AllLineupsTable from "pages/standings/components/AllLineupsTable";
import Competition from "model/Competition";
import {useQuery} from "react-query";
import getLineups from "api/getLineups";
import {Flashbar, Spinner} from "@awsui/components-react";
import StageSelect from "components/StageSelect";
import {useAuth} from "hooks/useAuth";
import AllLineupsItem from "model/AllLineupsItem";


interface AllLineupsComponentProps {
    competition: Competition;
}

const AllLineupsComponent: React.FC<AllLineupsComponentProps> = (props) => {

    const competition = props.competition;

    const [{league, user}] = useAuth();
    const [stage, setStage] = useState(-1);

    const {
        isLoading: lineupsIsLoading,
        data: lineups,
        error: getLineupsError
    } = useQuery<AllLineupsItem[], Error>(`getLineups-${competition.code}-${stage}`, () =>
        getLineups(competition, stage, league, user?.token)
    );

    const renderLineupTables = (): ReactNode => {
        if (lineupsIsLoading) {
            return (
                <Spinner size="big" variant="normal"/>
            );
        }
        if (getLineupsError) {
            return (
                <Flashbar
                    items={[{
                        header: "Error al cargar las alineaciones",
                        type: "error",
                        content: "Prueba a recargar la página.",
                        dismissible: false,
                    }]}
                />
            );
        }
        return lineups?.map(lineup => {
            return (
                <AllLineupsTable
                    key={lineup.username || ""}
                    username={lineup.username || ""}
                    riders={lineup.riders}
                    points={lineup.points}
                />
            );
        })
    };

    return (
        <SpaceBetween size={"l"}>
            <StageSelect setStage={setStage} length={competition.numberOfStages} />
            <SpaceBetween size="m" direction="horizontal">
                {renderLineupTables()}
            </SpaceBetween>
        </SpaceBetween>

    )
};

export default AllLineupsComponent;
