import React, {Dispatch, SetStateAction, useState} from "react";
import {Modal} from "@awsui/components-react";
import Header from "@awsui/components-react/header";
import Box from "@awsui/components-react/box";
import CreateNewLeagueButton from "pages/leagues/components/CreateNewLeagueButton";
import NewLeague from "model/NewLeague";
import {FlashbarProps} from "@awsui/components-react/flashbar/interfaces";
import SubmitFlashbar from "components/SubmitFlashbar";
import SpaceBetween from "@awsui/components-react/space-between";
import Form from "@awsui/components-react/form";
import Button from "@awsui/components-react/button";
import FormField from "@awsui/components-react/form-field";
import Input from "@awsui/components-react/input";
import Select from "@awsui/components-react/select";


interface NewLeaguesModalProps {
    showModal: boolean;
    setShowModal: Dispatch<SetStateAction<boolean>>;
}

enum LeaguePrivacy {
    PRIVATE = "PRIVATE",
    PUBLIC = "PUBLIC"
}

const NewLeaguesModal: React.FC<NewLeaguesModalProps> = (props) => {

    const [newLeague, setNewLeague] = useState<NewLeague>({});
    const [submitMessage, setSubmitMessage] = useState<FlashbarProps.MessageDefinition>();
    const [buttonEnabled, setButtonEnabled] = useState(true);

    const dismissModal = () => {
        props.setShowModal(false);
        setSubmitMessage(undefined);
    }

    const changeLeague = (newLeague: NewLeague): void => {
        setNewLeague(newLeague);
        setButtonEnabled(true);
    }

    return (
        <Modal
            visible={props.showModal}
            onDismiss={dismissModal}
            header={<Header variant={"h2"}>Crear nueva liga</Header>}
        >
            <SpaceBetween size={"s"}>
                <SpaceBetween size={"m"}>
                    {submitMessage && (
                        <SubmitFlashbar
                            submitMessage={submitMessage}
                            setSubmitMessage={setSubmitMessage}
                        />
                    )}
                    <form onSubmit={e => e.preventDefault()}>
                        <Form
                            actions={
                                <Box textAlign={"right"}>
                                    <SpaceBetween direction="horizontal" size="xs">
                                        <Button formAction="none" variant="normal" onClick={dismissModal}>
                                            Cerrar
                                        </Button>
                                        <CreateNewLeagueButton
                                            newLeague={newLeague}
                                            setShowModal={props.setShowModal}
                                            buttonEnabled={buttonEnabled}
                                            setButtonEnabled={setButtonEnabled}
                                            setSubmitMessage={setSubmitMessage}
                                        />
                                    </SpaceBetween>
                                </Box>
                            }
                        >
                            <SpaceBetween direction="vertical" size="l">
                                <FormField key={"name"} label={<Header variant="h2">Nombre</Header>}>
                                    <Input
                                        inputMode={"text"}
                                        onChange={({detail}) => changeLeague({
                                            ...newLeague,
                                            name: detail.value
                                        })}
                                        value={newLeague?.name || ""}
                                    />
                                </FormField>
                                <FormField key={"isPrivate"} label={<Header variant="h2">Privada / Pública</Header>}>
                                    <Select
                                        selectedOption={newLeague.isPrivate ? {
                                            label: "Privada",
                                            value: LeaguePrivacy.PRIVATE
                                        } : {
                                            label: "Pública",
                                            value: LeaguePrivacy.PUBLIC
                                        }}
                                        onChange={({detail}) => changeLeague({
                                            ...newLeague,
                                            isPrivate: detail.selectedOption.value === LeaguePrivacy.PRIVATE
                                        })}
                                        name={"isPrivate"}
                                        options={[
                                            {
                                                label: "Pública",
                                                value: LeaguePrivacy.PUBLIC
                                            },
                                            {
                                                label: "Privada",
                                                value: LeaguePrivacy.PRIVATE
                                            }
                                        ]}
                                    />
                                </FormField>
                                {newLeague.isPrivate && (
                                    <FormField key={"password"} label={<Header variant="h2">Contraseña</Header>}>
                                        <Input
                                            inputMode={"text"}
                                            type={"password"}
                                            onChange={({detail}) => changeLeague({
                                                ...newLeague,
                                                password: detail.value
                                            })}
                                            value={newLeague?.password || ""}
                                            disableBrowserAutocorrect
                                        />
                                    </FormField>
                                )}
                            </SpaceBetween>
                        </Form>
                    </form>
                </SpaceBetween>
            </SpaceBetween>
        </Modal>
    );
};

export default NewLeaguesModal;
