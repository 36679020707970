// Your web app's Firebase configuration
export const firebaseConfig = {
    apiKey: "AIzaSyAXL6ky257d4hVRgxv4UND-1_937LkHR6Q",
    authDomain: "porrazirauki-d3f78.firebaseapp.com",
    databaseURL: "https://porrazirauki-d3f78.firebaseio.com",
    projectId: "porrazirauki-d3f78",
    storageBucket: "porrazirauki-d3f78.appspot.com",
    messagingSenderId: "194575381836",
    appId: "1:194575381836:web:5bf8e5316b337e10d30be9"
};
