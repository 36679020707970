import Competition from "model/Competition";
import League from "model/League";
import Question from "model/Question";
import {fetchApiClient} from "common/fetchApiClient";
import {API_URL} from "common/const";
import UnauthorizedError from "api/errors/UnauthorizedError";
import { AxiosError } from "axios";


const getQuestions = async (competition: Competition, league: League, token: string | undefined): Promise<Question[]> => {
    const apiClient = fetchApiClient(token);

    return await apiClient.get<Question[]>(`${API_URL}/questions/${competition.code}/${league.leagueId}`)
        .then(response => {
            return response.data;
        }).catch((error: AxiosError) => {
            console.log(`Error fetching questions for Competition(${competition.code}) and League(${league.name})`, error);
            if (!!error.response && error.response.status === 403) {
                throw new UnauthorizedError();
            }
            throw error;
        });

}

export default getQuestions;
