import Flashbar from "@awsui/components-react/flashbar";
import Link from "@awsui/components-react/link";
import React from "react";


const NoOpenCompetitionsFlashbar: React.FC = () => {
    return (
        <Flashbar items={[{
            type: "info",
            header: "No hay competiciones abiertas.",
            content: <>Vete a <Link href="/standings" variant="primary" color="inverted">la clasificación</Link> si quieres ver los puntos de las competiciones anteriores.</>,
            dismissible: false
        }]} />
    );
};

export default NoOpenCompetitionsFlashbar;