import React, {Dispatch, SetStateAction} from "react";
import Button from "@awsui/components-react/button";
import {useMutation} from "react-query";
import {FlashbarProps} from "@awsui/components-react/flashbar/interfaces";
import addTeamSynonyms from "api/addTeamSynonyms";
import Box from "@awsui/components-react/box";
import {useAuth} from "hooks/useAuth";
import TeamSynonym from "model/TeamSynonym";


interface AddTeamSynonymsButtonProps {
    synonyms: Map<string, TeamSynonym> | undefined;
    setSubmitMessage: Dispatch<SetStateAction<FlashbarProps.MessageDefinition | undefined>>;
}

const AddTeamSynonymsButton: React.FC<AddTeamSynonymsButtonProps> = (props) => {

    const synonyms = props.synonyms;

    const [{user}] = useAuth();

    const {
        mutate: addTeamSynonymsMutation,
        isLoading: addTeamSynonymsIsLoading
    } = useMutation((synonyms: TeamSynonym[]) => addTeamSynonyms(synonyms, user?.token), {
            onSuccess: () => {
                props.setSubmitMessage({
                    header: "Team synonyms added correctly",
                    type: "success",
                    content: "",
                })
            },
            onError: () => {
                props.setSubmitMessage({
                    header: "Error when adding rider synonyms",
                    type: "error",
                    content: "Try to refresh the page.",
                })
            }
        }
    );

    const submitTeamSynonyms = () => {
        const selectedSynonyms: TeamSynonym[] = [];
        synonyms?.forEach((value, key) => {
            selectedSynonyms.push(new TeamSynonym(value.preferredNameId, key));
        });
        addTeamSynonymsMutation(selectedSynonyms);
    }

    return (
        <Box float="right">
            <Button
                disabled={!synonyms}
                iconAlign="left"
                loading={addTeamSynonymsIsLoading}
                variant="primary"
                onClick={submitTeamSynonyms}
            >
                Submit
            </Button>
        </Box>
    );
};

export default AddTeamSynonymsButton;
