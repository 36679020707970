import League from "model/League";
import PalmaresItem from "model/PalmaresItem";
import {fetchApiClient} from "common/fetchApiClient";
import {API_URL} from "common/const";
import UnauthorizedError from "api/errors/UnauthorizedError";
import {AxiosError} from "axios";


const getPalmares = async (league: League, token: string | undefined): Promise<PalmaresItem[]> => {
    const apiClient = fetchApiClient(token);

    return await apiClient.get<PalmaresItem[]>(`${API_URL}/palmares/${league.leagueId}`)
        .then(response => {
            return response.data;
        }).catch((error: AxiosError) => {
            console.log("Error fetching palmares for League(${league.id})", error);
            if (!!error.response && error.response.status === 403) {
                throw new UnauthorizedError();
            }
            throw error;
        });
}

export default getPalmares;
