import React, {SetStateAction, Dispatch} from "react";
import Rider from "model/Rider";
import Button from "@awsui/components-react/button";
import {useMutation} from "react-query";
import saveLineups from "api/saveLineups";
import Competition from "model/Competition";
import {Box, FlashbarProps, IconProps, Link} from "@awsui/components-react";
import League from "model/League";
import {useAuth} from "hooks/useAuth";


interface SubmitLineupsButtonProps {
    selectedRiders: Rider[];
    competition: Competition;
    league: League;
    buttonEnabled: boolean;
    setButtonEnabled: Dispatch<SetStateAction<boolean>>;
    buttonIconName: IconProps.Name | undefined;
    setButtonIconName: Dispatch<SetStateAction<IconProps.Name | undefined>>;
    setSubmitMessage: Dispatch<SetStateAction<FlashbarProps.MessageDefinition | undefined>>;
}

const SubmitLineupsButton: React.FC<SubmitLineupsButtonProps> = (props) => {

    const selectedRiders = props.selectedRiders;
    const competition = props.competition;
    const league = props.league;

    const [{user}] = useAuth();

    const {
        isLoading: saveLineupsIsLoading,
        mutate: callSaveLineups
    } = useMutation(() => saveLineups(competition, league, selectedRiders, user?.token), {
        onSuccess: () => {
            props.setSubmitMessage({
                header: "Alineación guardada correctamente",
                type: "success",
                content: <Box variant="span">Acuérdate de responder a las preguntas</Box>,
                action: <Button href="/questions">Preguntas</Button>
            });
            props.setButtonIconName("status-positive");
        },
        onError: () => {
            props.setSubmitMessage({
                header: "Error al guardar la alineación",
                type: "error",
                content: "Prueba a intentarlo de nuevo.",
            });
            props.setButtonIconName("status-negative");
        },
        onSettled: () => {
            props.setButtonEnabled(false);
        }
    });

    const saveRiders = () => {
        callSaveLineups();
    }

    const ridersValue: number = selectedRiders.length
        ? selectedRiders
            .map((item: { value: number; }) => item.value)
            .reduce((previousValue: number, currentValue: number) => previousValue + currentValue)
        : 0;

    return (
        <Button
            disabled={!props.buttonEnabled || (ridersValue > 150.05 || selectedRiders.length != 12)}
            iconAlign="left"
            iconName={props.buttonIconName}
            loading={saveLineupsIsLoading}
            variant="primary"
            onClick={saveRiders}
        >
            Guardar
        </Button>
    );
};

export default SubmitLineupsButton;
