import React, {Dispatch, SetStateAction} from "react";
import Button from "@awsui/components-react/button";
import {useMutation} from "react-query";
import Competition from "model/Competition";
import {FlashbarProps} from "@awsui/components-react/flashbar/interfaces";
import addRiders from "api/addRiders";
import Box from "@awsui/components-react/box";
import parseRiders from "pages/admin/util/parseRiders";
import Rider from "model/Rider";
import {useAuth} from "hooks/useAuth";
import MissingSynonymsError from "api/errors/MissingSynonymsError";
import Link from "@awsui/components-react/link";


interface AddRidersButtonProps {
    competition: Competition;
    ridersText: string;
    setRidersText: Dispatch<SetStateAction<string>>;
    buttonEnabled: boolean;
    setButtonEnabled: Dispatch<SetStateAction<boolean>>;
    setSubmitMessage: Dispatch<SetStateAction<FlashbarProps.MessageDefinition | undefined>>;
}

const AddRidersButton: React.FC<AddRidersButtonProps> = (props) => {

    const competition = props.competition;
    const ridersText = props.ridersText;

    const [{user}] = useAuth();

    const {
        mutate: addRidersMutation,
        isLoading: addRidersIsLoading
    } = useMutation((riders: Rider[]) => addRiders(competition, riders, user?.token), {
            onSuccess: () => {
                props.setSubmitMessage({
                    header: "Riders added correctly",
                    type: "success",
                    content: "",
                })
            },
            onError: (error: Error) => {
                if (error instanceof MissingSynonymsError) {
                    props.setSubmitMessage({
                        header: "Missing synonyms",
                        type: "error",
                        content: <>Go to either the <Link variant="info" href="/admin/add_rider_synonyms">rider
                            synonyms</Link> or <Link variant="info" href="/admin/add_team_synonyms">team
                            synonyms</Link> page.</>,
                    })
                } else {
                    props.setSubmitMessage({
                        header: "Error when adding riders",
                        type: "error",
                        content: "Try to refresh the page.",
                    })
                }
            },
            onSettled: () => {
                props.setButtonEnabled(false);
                props.setRidersText("");
            }
        }
    );

    const submitRiders = () => {
        addRidersMutation(parseRiders(ridersText));
    }

    return (
        <Box float="right">
            <Button
                disabled={!props.buttonEnabled || (ridersText === "")}
                iconAlign="left"
                loading={addRidersIsLoading}
                variant="primary"
                onClick={submitRiders}
            >
                Submit
            </Button>
        </Box>
    );
};

export default AddRidersButton;
