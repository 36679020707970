import League from "model/League";
import {fetchApiClient} from "common/fetchApiClient";
import {API_URL} from "common/const";
import {AxiosError} from "axios";
import RetryableError from "api/errors/RetryableError";


const getMemberships = async (token: string | undefined): Promise<League[]> => {
    const apiClient = fetchApiClient(token);

    return await apiClient.get<League[]>(`${API_URL}/memberships`)
        .then(response => {
            return response.data;
        }).catch((error: AxiosError) => {
            console.log("Error when fetching memberships", error);
            if (!!error.response && error.response.status === 403) {
                throw new RetryableError("Retryable error when fetching memberships");
            }
            throw error;
        });
}

export default getMemberships;
