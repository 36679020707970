import React, {ReactNode, useState} from "react";
import SpaceBetween from "@awsui/components-react/space-between";
import NavigationBar from "components/NavigationBar";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import {firebaseConfig} from "repository/firebaseUtils";
import "firebase/compat/auth";
import firebase from "firebase/compat/app";
import {Navigate} from "react-router-dom";
import {useAuth} from "hooks/useAuth";
import getMemberships from "api/getMemberships";
import {useMutation} from "react-query";
import League from "model/League";
import {FlashbarProps} from "@awsui/components-react/flashbar";
import Spinner from "@awsui/components-react/spinner";
import SubmitFlashbar from "components/SubmitFlashbar";
import Box from "@awsui/components-react/box";
import User from "../../model/User";
import signUp from "../../api/signUp";
import signIn from "../../api/signIn";
import {TextContent} from "@awsui/components-react";


// Fetch the firebase object
firebase.initializeApp(firebaseConfig);

const AuthPage: React.FC = () => {

    const [{isSignedIn, user, league}, dispatch] = useAuth();
    const [submitMessage, setSubmitMessage] = useState<FlashbarProps.MessageDefinition>();

    const uiConfig = () => {
        return ({
            // Callbacks
            callbacks: {
                signInSuccessWithAuthResult: (authResult: { user: firebase.User; additionalUserInfo: { isNewUser: boolean; providerId: string; }; }) => {
                    const user: firebase.User = authResult.user;
                    const isNewUser: boolean = authResult.additionalUserInfo.isNewUser;
                    const providerId: string = authResult.additionalUserInfo.providerId;

                    user.getIdToken()
                        .then((accessToken: string) => {
                            const internalUser = new User(
                                user.displayName || "",
                                user.email || "",
                                user.photoURL || "",
                                user.uid || "",
                                accessToken,
                                providerId
                            );

                            if (isNewUser) {
                                signUpMutation(internalUser);
                            } else {
                                signInMutation(internalUser);
                            }
                        }).catch((error: Error) => {
                        console.error("GetIdToken error: ", error);
                    });

                    return false;
                }
            },
            queryParameterForSignInSuccessUrl: "signInSuccessUrl",
            signInFlow: "popup",
            signInSuccessUrl: "/",
            signInOptions: [
                firebase.auth.GoogleAuthProvider.PROVIDER_ID,
                {
                    provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
                    requireDisplayName: true
                }
            ],
            tosUrl: "about",
            privacyPolicyUrl: function () {
                window.location.assign("/about");
            }
        })
    };

    const {
        mutate: signUpMutation,
        error: signUpError,
        isLoading: signUpLoading,
    } = useMutation<User, Error, User>((signedUpUser: User) => signUp(signedUpUser, signedUpUser?.token), {
        onSuccess: (signedUpUser: User) => {
            dispatch({
                user: signedUpUser,
                isSignedIn: true,
                isAdmin: signedUpUser?.email === "ibai.ros@hotmail.com"
            });
        },
        onError: () => {
            setSubmitMessage({
                header: "Error al registrarte",
                type: "error",
                content: "Prueba a recargar la página."
            });
        }
    });

    const {
        mutate: signInMutation,
        error: signInError,
        isLoading: signInLoading,
    } = useMutation<User, Error, User>((signedInUser: User) => signIn(signedInUser, signedInUser?.token), {
        onSuccess: (signedInUser: User) => {
            dispatch({
                user: signedInUser,
                isSignedIn: true,
                isAdmin: signedInUser?.email === "ibai.ros@hotmail.com"
            });
        },
        onError: () => {
            setSubmitMessage({
                header: "Error al iniciar sesión",
                type: "error",
                content: "Prueba a recargar la página."
            });
        }
    });

    const {
        mutate: getMembershipsMutation,
        error: getMembershipsError,
        isLoading: fetchingLeagues,
    } = useMutation<League[], Error>(() => getMemberships(user?.token), {
        onSuccess: (leagues: League[]) => {
            dispatch({
                user: {
                    ...user,
                    leagues: leagues || []
                },
                isSignedIn: true,
                isAdmin: user?.email === "ibai.ros@hotmail.com"
            });
        },
        onError: () => {
            setSubmitMessage({
                header: "Error al cargar las ligas a las que perteneces",
                type: "error",
                content: "Prueba a recargar la página."
            });
        }
    });

    const getBody = (): ReactNode => {
        if (fetchingLeagues || signUpLoading || signInLoading) {
            return (
                <Box variant={"h4"} display={"inline-block"} >
                    <Spinner size={"large"}/>
                    <TextContent>Iniciando sesión...</TextContent>
                </Box>
            );
        }
        if (getMembershipsError || signUpError || signInError) {
            return (
                <SubmitFlashbar
                    submitMessage={submitMessage}    
                    setSubmitMessage={setSubmitMessage}
                />
            );
        }
        if (isSignedIn && !league) {
            if (user?.leagues?.length === 0) {
                return (<Navigate to={"/leagues"}/>);
            } else {
                getMembershipsMutation();
            }
        }
    }

    return (isSignedIn && league) ? (
        <Navigate to="/"/>
    ) : (
        <SpaceBetween size="l">
            <NavigationBar/>
            {getBody()}
            {!isSignedIn && (<StyledFirebaseAuth uiConfig={uiConfig()} firebaseAuth={firebase.auth()}/>)}
        </SpaceBetween>
    );
};

export default AuthPage;
