import Competition from "model/Competition";
import League from "model/League";
import Rider from "model/Rider";
import {API_URL} from "common/const";
import {fetchApiClient} from "common/fetchApiClient";
import UnauthorizedError from "api/errors/UnauthorizedError";
import { AxiosError } from "axios";


const saveLineups = async (competition: Competition, league: League, selectedRiders: Rider[], token: string | undefined): Promise<void> => {
    const apiClient = fetchApiClient(token);

    await apiClient.put(`${API_URL}/lineups/${competition.code}/${league.leagueId}`, {
        riders: selectedRiders
    }).catch((error: AxiosError) => {
        console.log(`Error saving lineups for Competition(${competition.code}) and League(${league.name})`, selectedRiders, error);
        if (!!error.response && error.response.status === 403) {
            throw new UnauthorizedError();
        }
        throw error;
    });
}

export default saveLineups;
