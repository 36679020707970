import React, { ReactNode} from "react";
import Competition from "model/Competition";
import Icon from "@awsui/components-react/icon";
import SpaceBetween from "@awsui/components-react/space-between";
import Header from "@awsui/components-react/header";


interface CompetitionsHeaderProps {
    competition: Competition | undefined;
}

const CompetitionsHeader: React.FC<CompetitionsHeaderProps> = (props) => {

    const selectedCompetition = props.competition;
    const competitionIcon: ReactNode = selectedCompetition?.logoUrl && (<Icon size={"big"} url={selectedCompetition.logoUrl} />);
    
    return (
        <SpaceBetween direction="horizontal" size="s" >
            {competitionIcon}
            <Header variant={"h2"}>
                {selectedCompetition
                    ? `${selectedCompetition.name} ${selectedCompetition.year}`
                    : "Selecciona una competitición"
                }
            </Header>
        </SpaceBetween>
    );
}

export default CompetitionsHeader;
