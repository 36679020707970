class TeamSynonym {
    preferredNameId: number;
    synonym: string;
    distance?: number;

    constructor(preferredNameId: number, synonym: string, distance?: number) {
        this.preferredNameId = preferredNameId;
        this.synonym = synonym;
        this.distance = distance;
    }
}

export default TeamSynonym;
