import Box from "@awsui/components-react/box";
import React, { ReactNode } from "react";


interface EmptyStateProps {
    title: string;
    subtitle: string;
    action?: ReactNode;
}

const EmptyState: React.FC<EmptyStateProps> = (props) => {
    return (
        <>
            <Box textAlign={"center"}>
                <Box padding={"xxs"} margin={"xxs"}>
                    <b>{props.title}</b>
                </Box>
                <Box margin={"xxs"}>
                    <p>{props.subtitle}</p>
                </Box>
                <Box margin={"xs"}>
                    <p>{props.action}</p>
                </Box>
            </Box>
        </>
    );
};

export default EmptyState;