import React from "react";
import SegmentedControl from "@awsui/components-react/segmented-control";
import { useAuth } from "hooks/useAuth";


const LeagueSelector: React.FC = () => {

    const [{ user, league }, dispatch] = useAuth();

    return (
        <SegmentedControl
            selectedId={`${league?.leagueId}`}
            onChange={({ detail }) => {
                const newLeague = user?.leagues?.find(league => league.leagueId === parseInt(detail.selectedId));
                dispatch({
                    user: user,
                    isSignedIn: true,
                    isAdmin: user?.email === "ibai.ros@hotmail.com",
                    league: newLeague
                })
            }}
            label="Mis ligas"
            options={user?.leagues?.map(league => ({
                id: `${league.leagueId}`,
                text: league.name
            })) || []}
        />
    );
}

export default LeagueSelector;