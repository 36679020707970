import SpaceBetween from "@awsui/components-react/space-between";
import React from "react";
import NavigationBar from "components/NavigationBar";
import Container from "@awsui/components-react/container";
import Header from "@awsui/components-react/header";
import Box from "@awsui/components-react/box";
import Link from "@awsui/components-react/link";


const AdminPage: React.FC = () => (
    <SpaceBetween size="l">
        <NavigationBar />
        <Container
            header={<Header>Admin page</Header>}
        >
            <Container
                header={<Header variant="h3">Riders</Header>}
            >
                <SpaceBetween size="m">
                    <Box>
                        <Link variant="primary" href="/admin/add_riders">Add riders</Link>
                    </Box>
                    <Box>
                        <Link variant="primary" href="/admin/set_basqueness">Set rider basqueness</Link>
                    </Box>
                </SpaceBetween>
            </Container>
            <Container
                header={<Header variant="h3">Points</Header>}
            >
                <SpaceBetween size="m">
                    <Box>
                        <Link variant="primary" href="/admin/add_points">Add points</Link>
                    </Box>
                    <Box>
                        <Link variant="primary" href="/admin/recalculate_points">Recalculate points</Link>
                    </Box>
                    <Box>
                        <Link variant="primary" href="/admin/delete_points">Delete points</Link>
                    </Box>
                </SpaceBetween>
            </Container>
            <Container
                header={<Header variant="h3">Competitions</Header>}
            >
                <SpaceBetween size="m">
                    <Box>
                        <Link variant="primary" href="/admin/add_competition">Add competition</Link>
                    </Box>
                    <Box>
                        <Link variant="primary" href="/admin/edit_competition">Edit competition</Link>
                    </Box>
                </SpaceBetween>
            </Container>
            <Container
                header={<Header variant="h3">Questions</Header>}
            >
                <SpaceBetween size="m">
                    <Box>
                        <Link variant="primary" href="/admin/add_question">Add questions</Link>
                    </Box>
                    <Box>
                        <Link variant="primary" href="/admin/select_answers">Select answers</Link>
                    </Box>
                </SpaceBetween>
            </Container>
            <Container
                header={<Header variant="h3">Synonyms</Header>}
            >
                <SpaceBetween size="m">
                    <Box>
                        <Link variant="primary" href="/admin/add_rider_synonyms">Add rider synonyms</Link>
                    </Box>
                    <Box>
                        <Link variant="primary" href="/admin/add_team_synonyms">Add team synonyms</Link>
                    </Box>
                </SpaceBetween>
            </Container>
        </Container>
    </SpaceBetween>
);

export default AdminPage;
