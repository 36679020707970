import {useAuth} from "hooks/useAuth";
import React from "react";
import {Navigate} from "react-router-dom";
import UnauthorizedContainer from "components/UnauthorizedContainer";


/* eslint-disable  @typescript-eslint/no-explicit-any */
const ProtectedRoute: React.FC<any> = ({children}) => {

    const [{isSignedIn}] = useAuth();

    return isSignedIn ? (
        <UnauthorizedContainer>
            {children}
        </UnauthorizedContainer>
    ) : (
        <Navigate to={"/"}/>
    );
}

export default ProtectedRoute;
