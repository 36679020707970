import Competition from "model/Competition";
import Rider from "model/Rider";
import {fetchApiClient} from "common/fetchApiClient";
import {API_URL} from "common/const";
import {AxiosError} from "axios";
import MissingSynonymsError from "api/errors/MissingSynonymsError";
import UnauthorizedError from "api/errors/UnauthorizedError";


const addRiders = async (competition: Competition, riders: Rider[], token: string | undefined): Promise<void> => {
    const apiClient = fetchApiClient(token);

    await apiClient.put(`${API_URL}/riders/${competition.code}`, {
        riders: riders
    }).catch((error: AxiosError) => {
        console.log(`Error adding riders for Competition(${competition.code})`, riders, error);
        if (!!error.response && error.response.status === 428) {
            throw new MissingSynonymsError("Missing synonyms");
        }
        if (!!error.response && error.response.status === 403) {
            throw new UnauthorizedError();
        }
        throw error;
    });
}

export default addRiders;
