import SpaceBetween from "@awsui/components-react/space-between";
import React, { useState } from "react";
import NavigationBar from "components/NavigationBar";
import Container from "@awsui/components-react/container";
import Header from "@awsui/components-react/header";
import { FlashbarProps } from "@awsui/components-react/flashbar/interfaces";
import Competition from "model/Competition";
import CompetitionsSelect from "components/CompetitionsSelect";
import SelectAnswersForm from "pages/admin/pages/select_answers/components/SelectAnswersForm";
import Box from "@awsui/components-react/box";
import Link from "@awsui/components-react/link";
import SubmitFlashbar from "components/SubmitFlashbar";


const EditCompetitionPage: React.FC = () => {

    const [competition, setCompetition] = useState<Competition>();
    const [submitMessage, setSubmitMessage] = useState<FlashbarProps.MessageDefinition>();

    return (
        <SpaceBetween size="l">
            <NavigationBar />
            <Container
                header={
                    <Box variant={"span"}>
                        <Header variant="h1">Select answers</Header>
                        <Link variant={"info"} href={"/admin"}>Back</Link>
                    </Box>
                }
            >
                <SpaceBetween size={"m"}>
                    <CompetitionsSelect
                        selectedCompetition={competition}
                        setSelectedCompetition={setCompetition}
                        disabledStates={[]}
                    />
                    {submitMessage && (
                        <SubmitFlashbar
                            submitMessage={submitMessage}
                            setSubmitMessage={setSubmitMessage}
                        />
                    )}
                    {competition && (
                        <SelectAnswersForm competition={competition} />
                    )}
                </SpaceBetween>
            </Container>
        </SpaceBetween>
    )
};

export default EditCompetitionPage;
