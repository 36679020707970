class EditedRider {
    riderId: number;
    name: string;
    country: string;

    constructor(riderId: number, name: string, country: string) {
        this.riderId = riderId;
        this.name = name;
        this.country = country;
    }
}

export default EditedRider;
