import React, {useEffect} from "react";
import {usePrefs} from "hooks/usePrefs";
import AwsuiDarkMode from "awsui-dark-mode";


/* eslint-disable  @typescript-eslint/no-explicit-any */
const DarkModeContainer: React.FC<any> = ({children}) => {

    const [{darkModeEnabled}] = usePrefs();

    useEffect(() => {
        if (darkModeEnabled) {
            document.body.setAttribute("data-theme", "dark");
        } else {
            document.body.setAttribute("data-theme", "light");
        }
    }, [darkModeEnabled])

    return (
        <AwsuiDarkMode disabled={!darkModeEnabled}>
            {children}
        </AwsuiDarkMode>
    );
}

export default DarkModeContainer;
