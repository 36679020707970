import SpaceBetween from "@awsui/components-react/space-between";
import React, {useState} from "react";
import NavigationBar from "components/NavigationBar";
import Container from "@awsui/components-react/container";
import Header from "@awsui/components-react/header";
import Textarea from "@awsui/components-react/textarea";
import {FlashbarProps} from "@awsui/components-react/flashbar/interfaces";
import Box from "@awsui/components-react/box";
import TeamAndSynonyms from "model/TeamAndSynonyms";
import Select from "@awsui/components-react/select";
import TeamSynonym from "model/TeamSynonym";
import GetTeamSynonymsButton from "pages/admin/pages/add_team_synonyms/components/GetTeamSynonymsButton";
import AddTeamSynonymsButton from "pages/admin/pages/add_team_synonyms/components/AddTeamSynonymsButton";
import Link from "@awsui/components-react/link";
import SubmitFlashbar from "components/SubmitFlashbar";
import Competition from "model/Competition";
import CompetitionsSelect from "components/CompetitionsSelect";
import {CompetitionState} from "model/CompetitionState";


const AddTeamSynonymsPage: React.FC = () => {

    const [ridersText, setRidersText] = useState("");
    const [getSynonymsButtonEnabled, setGetSynonymsButtonEnabled] = useState(true);
    const [submitMessage, setSubmitMessage] = useState<FlashbarProps.MessageDefinition>();
    const [teamSynonyms, setTeamSynonyms] = useState<TeamAndSynonyms[]>();
    const [selectedSynonyms, setSelectedSynonyms] = useState<Map<string, TeamSynonym>>(new Map());
    const [selectedCompetition, setSelectedCompetition] = useState<Competition>();

    const changeSelectedSynonyms = (teamSynonym: TeamAndSynonyms, selectedOption: string | undefined): void => {
        const selectedSynonym = teamSynonym.synonyms
            .filter(synonym => synonym.synonym === selectedOption)
            .find(s => s);
        if (!!selectedSynonym) {
            console.log("SelectedSynonym: ", selectedSynonym);
            console.log("SelectedSynonyms: ", selectedSynonyms);
            console.log("SelectedSynonyms.get: ", selectedSynonyms.get(teamSynonym.teamName));
            const newMap = selectedSynonyms.set(teamSynonym.teamName, selectedSynonym);
            setSelectedSynonyms(newMap);
        }
    }

    return (
        <SpaceBetween size="l">
            <NavigationBar/>
            <Container
                header={
                    <Box variant={"span"}>
                        <Header variant="h1">Add team synonyms</Header>
                        <Link variant={"info"} href={"/admin"}>Back</Link>
                    </Box>
                }
            >
                <SpaceBetween size={"m"}>
                    <CompetitionsSelect
                        selectedCompetition={selectedCompetition}
                        setSelectedCompetition={setSelectedCompetition}
                        disabledStates={[CompetitionState.STARTED, CompetitionState.FINISHED]}
                    />
                    {selectedCompetition &&
                        <SpaceBetween size={"m"}>
                            {submitMessage && (
                                <SubmitFlashbar
                                    submitMessage={submitMessage}
                                    setSubmitMessage={setSubmitMessage}
                                />
                            )}
                            <Container header={<Header variant="h3">Initial teams</Header>}>
                                <SpaceBetween size={"m"}>
                                    <Textarea
                                        onChange={({detail}) => {
                                            setRidersText(detail.value);
                                            setGetSynonymsButtonEnabled(true);
                                        }}
                                        value={ridersText}
                                        disableBrowserAutocorrect
                                        disableBrowserSpellcheck
                                        rows={15}
                                        placeholder="Add teams"
                                    />
                                    <GetTeamSynonymsButton
                                        ridersText={ridersText}
                                        setRidersText={setRidersText}
                                        buttonEnabled={getSynonymsButtonEnabled}
                                        setButtonEnabled={setGetSynonymsButtonEnabled}
                                        setSubmitMessage={setSubmitMessage}
                                        setTeamSynonyms={setTeamSynonyms}
                                        competition={selectedCompetition}
                                    />
                                </SpaceBetween>
                            </Container>

                            {(teamSynonyms != undefined && teamSynonyms.length > 0) ? (
                                <Container header={<Header variant="h3">Synonyms</Header>}>
                                    <SpaceBetween size={"m"}>
                                        {teamSynonyms.map(teamSynonym => {
                                            return (
                                                <Box key={`${teamSynonym.teamName}-box`}>
                                                    <Header variant={"h3"}>{teamSynonym.teamName}</Header>
                                                    <Select
                                                        name={teamSynonym.teamName}
                                                        selectedOption={{
                                                            label: selectedSynonyms.get(teamSynonym.teamName)?.synonym,
                                                            value: selectedSynonyms.get(teamSynonym.teamName)?.synonym
                                                        }}
                                                        onChange={({detail}) =>
                                                            changeSelectedSynonyms(teamSynonym, detail.selectedOption.value)}
                                                        options={teamSynonym.synonyms.map((synonym, index) => ({
                                                            label: `${index} - ${synonym.synonym} (${synonym.distance})`,
                                                            value: synonym.synonym
                                                        }))}
                                                        selectedAriaLabel="Selected"
                                                        key={`${teamSynonym.teamName}-select`}
                                                    />
                                                </Box>
                                            );
                                        })}
                                        <Box float="right">
                                            <AddTeamSynonymsButton
                                                synonyms={selectedSynonyms}
                                                setSubmitMessage={setSubmitMessage}
                                            />
                                        </Box>
                                    </SpaceBetween>
                                </Container>
                            ) : null}
                        </SpaceBetween>
                    }
                </SpaceBetween>
            </Container>
        </SpaceBetween>
    )
};

export default AddTeamSynonymsPage;
