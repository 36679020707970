import axios, {AxiosInstance, AxiosRequestConfig, Method} from "axios";
import {API_URL} from "common/const";


export const fetchApiClient = (token?: string): AxiosInstance => {

    const defaultOptions = {
        baseURL: API_URL,
        method: "get" as Method,
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        },
    };

    // Create instance
    const instance = axios.create(defaultOptions);

    // Set the AUTH token for any request
    instance.interceptors.request.use((config: AxiosRequestConfig) => {
        if (!!config.headers) {
            config.headers['x-auth-token'] = `${token}`;
        }
        return config;
    });

    return instance;
};
