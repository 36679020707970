import SpaceBetween from "@awsui/components-react/space-between";
import React, { useState } from "react";
import NavigationBar from "components/NavigationBar";
import Container from "@awsui/components-react/container";
import Header from "@awsui/components-react/header";
import Competition from "model/Competition";
import CompetitionsSelect from "components/CompetitionsSelect";
import CompetitionsHeader from "components/CompetitionsHeader";
import { CompetitionState } from "model/CompetitionState";
import { FlashbarProps } from "@awsui/components-react/flashbar/interfaces";
import DeletePointsButton from "pages/admin/pages/delete_points/components/DeletePointsButton";
import Box from "@awsui/components-react/box";
import Link from "@awsui/components-react/link";
import SubmitFlashbar from "components/SubmitFlashbar";
import StageSelect from "components/StageSelect";


const DeletePointsPage: React.FC = () => {

    const [selectedCompetition, setSelectedCompetition] = useState<Competition>();
    const [buttonEnabled, setButtonEnabled] = useState(true);
    const [stage, setStage] = useState(-1);
    const [submitMessage, setSubmitMessage] = useState<FlashbarProps.MessageDefinition>();

    return (
        <SpaceBetween size="l">
            <NavigationBar />
            <Container
                header={
                    <Box variant={"span"}>
                        <Header variant="h1">Delete points</Header>
                        <Link variant={"info"} href={"/admin"}>Back</Link>
                    </Box>
                }
            >
                <SpaceBetween size={"m"}>
                    <CompetitionsSelect
                        selectedCompetition={selectedCompetition}
                        setSelectedCompetition={setSelectedCompetition}
                        disabledStates={[CompetitionState.NOT_STARTED, CompetitionState.FINISHED]}
                    />
                    {selectedCompetition &&
                        <Container
                            variant={"default"}
                            header={<CompetitionsHeader competition={selectedCompetition} />}
                        >
                            <SpaceBetween size={"m"}>
                                {submitMessage && (
                                    <SubmitFlashbar
                                        submitMessage={submitMessage}
                                        setSubmitMessage={setSubmitMessage}
                                    />
                                )}
                                <StageSelect
                                    setStage={setStage}
                                    length={selectedCompetition.numberOfStages}
                                    lastStage={selectedCompetition.lastStage}
                                />
                                <DeletePointsButton
                                    competition={selectedCompetition}
                                    stage={stage}
                                    buttonEnabled={buttonEnabled}
                                    setButtonEnabled={setButtonEnabled}
                                    setSubmitMessage={setSubmitMessage}
                                />
                            </SpaceBetween>
                        </Container>
                    }
                </SpaceBetween>
            </Container>
        </SpaceBetween>
    )
};

export default DeletePointsPage;
