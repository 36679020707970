import { Dispatch, useEffect, useRef, useState } from "react";


/* eslint-disable  @typescript-eslint/no-explicit-any */
const useLocalStorageState = (
    key: string,
    defaultValue: any = {},
    { serialize = JSON.stringify, deserialize = JSON.parse } = {}
): [any, Dispatch<any>] => {
    const [state, setState] = useState(() => {
        const valueInLocalStorage = window.localStorage.getItem(key);
        const mergeStoredAndDefaultValues = () => ({
            ...defaultValue,
            ...deserialize(valueInLocalStorage || "{}")
        });

        if (!valueInLocalStorage) {
            return typeof defaultValue === "function" ? defaultValue() : defaultValue;
        }

        if (
            typeof deserialize(valueInLocalStorage) === "object" &&
            typeof defaultValue === "object"
        ) {
            return mergeStoredAndDefaultValues();
        }

        return deserialize(valueInLocalStorage);
    });

    const prevKeyRef = useRef(key);

    useEffect(() => {
        const prevKey = prevKeyRef.current;
        if (prevKey !== key) {
            window.localStorage.removeItem(prevKey);
        }
        prevKeyRef.current = key;
        window.localStorage.setItem(key, serialize(state));
    }, [key, state, serialize]);

    return [state, setState];
};

export { useLocalStorageState };
