import SpaceBetween from "@awsui/components-react/space-between";
import React, { useState } from "react";
import NavigationBar from "components/NavigationBar";
import Container from "@awsui/components-react/container";
import Header from "@awsui/components-react/header";
import { FlashbarProps } from "@awsui/components-react/flashbar/interfaces";
import FormField from "@awsui/components-react/form-field";
import Competition from "model/Competition";
import Form from "@awsui/components-react/form";
import Input from "@awsui/components-react/input";
import Button from "@awsui/components-react/button";
import EditCompetitionButton from "pages/admin/pages/edit_competition/components/EditCompetitionButton";
import CompetitionsSelect from "components/CompetitionsSelect";
import Select from "@awsui/components-react/select";
import { CompetitionState } from "model/CompetitionState";
import Icon from "@awsui/components-react/icon";
import Grid from "@awsui/components-react/grid";
import Box from "@awsui/components-react/box";
import Link from "@awsui/components-react/link";
import SubmitFlashbar from "components/SubmitFlashbar";


const EditCompetitionPage: React.FC = () => {

    const [competition, setCompetition] = useState<Competition>();
    const [buttonEnabled, setButtonEnabled] = useState(true);
    const [submitMessage, setSubmitMessage] = useState<FlashbarProps.MessageDefinition>();

    const changeCompetition = (competition: Competition): void => {
        setCompetition(competition);
        setButtonEnabled(true);
    }

    return (
        <SpaceBetween size="l">
            <NavigationBar />
            <Container
                header={
                    <Box variant={"span"}>
                        <Header variant="h1">Edit competition</Header>
                        <Link variant={"info"} href={"/admin"}>Back</Link>
                    </Box>
                }
            >
                <SpaceBetween size={"m"}>
                    <CompetitionsSelect
                        selectedCompetition={competition}
                        setSelectedCompetition={setCompetition}
                        disabledStates={[]}
                    />
                    {submitMessage && (
                        <SubmitFlashbar
                            submitMessage={submitMessage}
                            setSubmitMessage={setSubmitMessage}
                        />
                    )}
                    <form onSubmit={e => e.preventDefault()}>
                        <Form
                            actions={
                                <SpaceBetween direction="horizontal" size="xs">
                                    <Button formAction="none" variant="link" href="/">
                                        Cancelar
                                    </Button>
                                    <EditCompetitionButton
                                        competition={competition}
                                        setCompetition={setCompetition}
                                        buttonEnabled={buttonEnabled}
                                        setButtonEnabled={setButtonEnabled}
                                        setSubmitMessage={setSubmitMessage}
                                    />
                                </SpaceBetween>
                            }
                        >
                            <SpaceBetween direction="vertical" size="l">
                                <FormField key={"name"} label={<Header variant="h2">Name</Header>}>
                                    <Input
                                        inputMode="text"
                                        onChange={({ detail }) => changeCompetition({
                                            ...competition,
                                            name: detail.value
                                        })}
                                        value={competition?.name || ""}
                                    />
                                </FormField>
                                <FormField key={"year"} label={<Header variant="h2">Year</Header>}>
                                    <Input
                                        inputMode="numeric"
                                        onChange={({ detail }) => changeCompetition({
                                            ...competition,
                                            year: parseInt(detail.value)
                                        })}
                                        value={`${competition?.year}`}
                                    />
                                </FormField>
                                <FormField key={"code"} label={<Header variant="h2">Code</Header>}>
                                    <Input
                                        inputMode="text"
                                        onChange={({ detail }) => changeCompetition({
                                            ...competition,
                                            code: detail.value
                                        })}
                                        value={competition?.code || ""}
                                    />
                                </FormField>
                                <FormField key={"logoUrl"} label={<Header variant="h2">Logo URL</Header>}>
                                    <Grid
                                        gridDefinition={[{ colspan: 11 }, { colspan: 1 }]}
                                    >
                                        <Input
                                            inputMode="url"
                                            onChange={({ detail }) => changeCompetition({
                                                ...competition,
                                                logoUrl: detail.value
                                            })}
                                            value={competition?.logoUrl || ""}
                                        />
                                        <Icon url={competition?.logoUrl} />
                                    </Grid>
                                </FormField>
                                <FormField key={"state"} label={<Header variant="h2">State</Header>}>
                                    <Select
                                        name="State"
                                        selectedOption={{
                                            label: competition?.state,
                                            value: competition?.state
                                        }}
                                        onChange={({ detail }) => changeCompetition({
                                            ...competition,
                                            state: CompetitionState[detail.selectedOption.value as keyof typeof CompetitionState]
                                        })}
                                        options={[CompetitionState.NOT_STARTED, CompetitionState.STARTED, CompetitionState.FINISHED].map(state => ({
                                            label: state,
                                            value: state
                                        }))}
                                        selectedAriaLabel="Selected"
                                    />
                                </FormField>
                                <FormField key={"numberOfStages"} label={<Header variant="h2">Number of stages</Header>}>
                                    <Input
                                        inputMode="numeric"
                                        onChange={({ detail }) => changeCompetition({
                                            ...competition,
                                            numberOfStages: parseInt(detail.value)
                                        })}
                                        value={`${competition?.numberOfStages}`}
                                    />
                                </FormField>
                                <FormField key={"shortName"} label={<Header variant="h2">Short name</Header>}>
                                    <Input
                                        inputMode="text"
                                        onChange={({ detail }) => changeCompetition({
                                            ...competition,
                                            shortName: detail.value
                                        })}
                                        value={competition?.shortName || ""}
                                    />
                                </FormField>
                                <FormField key={"lastStage"} label={<Header variant="h2">Last stage</Header>}>
                                    <Input
                                        inputMode="numeric"
                                        onChange={({ detail }) => changeCompetition({
                                            ...competition,
                                            lastStage: parseInt(detail.value)
                                        })}
                                        value={`${competition?.lastStage}`}
                                    />
                                </FormField>
                                <FormField key={"updating"} label={<Header variant="h2">Updating</Header>}>
                                    <Input
                                        inputMode="text"
                                        onChange={({ detail }) => changeCompetition({
                                            ...competition,
                                            updating: JSON.parse(detail.value)
                                        })}
                                        value={Boolean(competition?.updating).toString()}
                                    />
                                </FormField>
                            </SpaceBetween>
                        </Form>
                    </form>
                </SpaceBetween>
            </Container>
        </SpaceBetween>
    )
};

export default EditCompetitionPage;
