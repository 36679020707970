import Box from "@awsui/components-react/box";
import Popover from "@awsui/components-react/popover";
import Rider from "model/Rider";
import React from "react";
import FlagIcon from "components/icons/FlagIcon";
import KeyValueItem from "components/KeyValueItem";
import SpaceBetween from "@awsui/components-react/space-between";
import { Link } from "@awsui/components-react";
import StarIcon from "./icons/StarIcon";


interface RiderPopoverProps {
    rider: Rider;
}

const RiderPopover: React.FC<RiderPopoverProps> = (props) => {

    const rider = props.rider;

    return (
        <Popover
            position="top"
            size="large"
            triggerType="text"
            content={
                <SpaceBetween direction={"vertical"} size={"s"}>
                    <SpaceBetween direction="horizontal" size="s">
                        <Box variant="strong">{rider.name}</Box>
                        <Link
                            external
                            externalIconAriaLabel={"Opens in a new tab"}
                            href={rider.link}
                        />
                    </SpaceBetween>

                    <SpaceBetween direction="horizontal" size="s">
                        {rider.img && (
                            <img style={{ width: '60px', resize: 'inline', flex: 1 }} src={rider.img} alt={rider.name} />
                        )}
                        <SpaceBetween size={"xxs"} direction={"vertical"}>
                            <KeyValueItem keyItem={"Equipo"} valueItem={<Box>{rider.team}</Box>} />
                            {rider.country && (
                                <KeyValueItem keyItem={"País"} valueItem={<FlagIcon country={rider.country} />} />
                            )}
                        </SpaceBetween>
                    </SpaceBetween>
                </SpaceBetween>
            }
        >
            <Box variant="span">{rider.ideal ? <StarIcon /> : null} {rider.name}</Box>
        </Popover>
    );
};

export default RiderPopover;
