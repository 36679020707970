import {fetchApiClient} from "common/fetchApiClient";
import {API_URL} from "common/const";
import NewCompetition from "model/admin/NewCompetition";
import { AxiosError } from "axios";
import UnauthorizedError from "api/errors/UnauthorizedError";


const addCompetition = async (competition: NewCompetition, token: string | undefined): Promise<void> => {
    const apiClient = fetchApiClient(token);

    await apiClient.put(`${API_URL}/competitions`, {
        competition: competition
    }).catch((error: AxiosError) => {
        console.log(`Error adding Competition(${competition})`, error);
        if (!!error.response && error.response.status === 403) {
            throw new UnauthorizedError();
        }
        throw error;
    });
}

export default addCompetition;
