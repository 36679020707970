import React from "react";
import Table, {TableProps} from "@awsui/components-react/table";
import Header from "@awsui/components-react/header";
import Competition from "model/Competition";
import StandingsItem from "model/StandingsItem";
import getStandings from "api/getStandings";
import {useQuery} from "react-query";
import useTable from "hooks/useTable";
import Pagination from "@awsui/components-react/pagination";
import TextFilter from "@awsui/components-react/text-filter";
import CollectionPreferences from "@awsui/components-react/collection-preferences";
import {Navigate} from "react-router-dom";
import {useAuth} from "hooks/useAuth";
import {formatNumber} from "common/formatNumber";
import Box from "@awsui/components-react/box";
import PositionIcon from "../../../components/icons/PositionIcon";


interface StandingsTableProps {
    competition: Competition;
}

const StandingsTable: React.FC<StandingsTableProps> = (props) => {

    const columnDefinitions: TableProps.ColumnDefinition<StandingsItem>[] = [
        {
            id: "position",
            header: "Puesto",
            cell: (item: StandingsItem) => item.position || "-",
            sortingField: "position"
        },
        {
            id: "username",
            header: "Usuario",
            cell: (item: StandingsItem) =>
                <Box variant="span">
                    <PositionIcon competition={competition} length={standings?.length} position={item.position}/>
                    {item.user || "-"}
                </Box>,
            sortingField: "user"
        },
        {
            id: "points",
            header: "Puntos",
            cell: (item: StandingsItem) => item.points || "-",
            sortingField: "points"
        },
        {
            id: "value",
            header: "Valor de la alineación",
            cell: (item: StandingsItem) => `${formatNumber(item.value, 1)} mls.`,
            sortingField: "value"
        }
    ];

    const competition = props.competition;

    const [{league, user}] = useAuth();

    if (!league) {
        return (<Navigate to={"/"}/>);
    }

    const {
        isLoading: standingsIsLoading,
        data: standings,
        error: getStandingsError
    } = useQuery<StandingsItem[], Error>(`getStandings-${competition.code}`, () =>
        getStandings(competition, league, user?.token)
    );

    const {
        preferences,
        setPreferences,
        items,
        collectionProps,
        filterProps,
        paginationProps,
        collectionPreferencesProps
    } = useTable(standings || [], {
        emptyStateSubtitle: getStandingsError as Error,
        emptyStateTitle: "No hay estadísticas."
    });

    return (
        <Table
            {...collectionProps}
            loadingText="Cargando las estadísticas"
            columnDefinitions={columnDefinitions}
            items={items}
            loading={standingsIsLoading}
            wrapLines={false}
            header={<Header>Clasificación</Header>}
            pagination={
                <Pagination
                    {...paginationProps}
                    ariaLabels={{
                        nextPageLabel: "Siguiente página",
                        previousPageLabel: "Página anterior",
                        pageLabel: pageNumber => `Página ${pageNumber}`
                    }}
                />
            }
            filter={
                <TextFilter
                    {...filterProps}
                    filteringPlaceholder="Buscar por texto"
                />
            }
            preferences={
                <CollectionPreferences
                    {...collectionPreferencesProps}
                    preferences={preferences}
                    onConfirm={({detail}) => setPreferences(detail)}
                />
            }
        />
    );
};

export default StandingsTable;