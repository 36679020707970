import React from "react";
import SpaceBetween from "@awsui/components-react/space-between";
import NavigationBar from "components/NavigationBar";
import PalmaresTable from "./components/PalmaresTable";
import Header from "@awsui/components-react/header";
import Container from "@awsui/components-react/container";


const PalmaresPage: React.FC = () => {
    return (
        <SpaceBetween size={"l"}>
            <NavigationBar/>
            <Container
                variant={"default"}
                header={
                    <Header variant={"h2"}>
                        Histórico
                    </Header>
                }
            >
                <PalmaresTable />
            </Container>
        </SpaceBetween>
    )
};

export default PalmaresPage;
