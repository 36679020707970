import React, { useState } from "react";
import SpaceBetween from "@awsui/components-react/space-between";
import NavigationBar from "components/NavigationBar";
import Container from "@awsui/components-react/container";
import Header from "@awsui/components-react/header";
import StatsTable from "pages/stats/components/StatsTable";
import Competition from "model/Competition";
import CompetitionsSelect from "components/CompetitionsSelect";


const StatsPage: React.FC = () => {

    const [selectedCompetition, setSelectedCompetition] = useState<Competition>();

    return (
        <SpaceBetween size={"l"}>
            <NavigationBar />
            <Container
                variant={"default"}
                header={
                    <Header variant={"h2"}>
                        Estadísticas
                    </Header>
                }
            >
                <SpaceBetween size={"l"}>
                    <CompetitionsSelect
                        selectedCompetition={selectedCompetition}
                        setSelectedCompetition={setSelectedCompetition}
                        disabledStates={[]}
                    />
                    {selectedCompetition && (
                        <StatsTable
                            competition={selectedCompetition}
                        />
                    )}
                </SpaceBetween>
            </Container>
        </SpaceBetween>
    )
};

export default StatsPage;
