import React from "react";
import Icon from "@awsui/components-react/icon";

const StarIcon: React.FC = () => {
    return (
        <Icon
            url="https://img.icons8.com/color/96/000000/filled-star--v1.png"
        />
    );
};

export default StarIcon;
