import React, {SetStateAction, Dispatch} from "react";
import Button from "@awsui/components-react/button";
import {useMutation} from "react-query";
import selectAnswers from "api/selectAnswers";
import Competition from "model/Competition";
import {FlashbarProps} from "@awsui/components-react/flashbar/interfaces";
import UserAnswer from "model/UserAnswer";
import parseUserAnswers from "pages/admin/util/parseUserAnswers";
import {useAuth} from "hooks/useAuth";


interface SelectAnswersButtonProps {
    selectedAnswers: Map<number, number>;
    numberOfQuestions: number;
    competition: Competition;
    buttonEnabled: boolean;
    setButtonEnabled: Dispatch<SetStateAction<boolean>>;
    setSubmitMessage: Dispatch<SetStateAction<FlashbarProps.MessageDefinition | undefined>>;
}

const SelectAnswersButton: React.FC<SelectAnswersButtonProps> = (props) => {

    const selectedAnswers = props.selectedAnswers;
    const competition = props.competition;

    const [{user}] = useAuth();

    const {
        mutate: callSaveAnswers,
        isLoading: selectAnswersIsLoading
    } = useMutation((answers: UserAnswer[]) => selectAnswers(competition, answers, user?.token), {
        onSuccess: () => {
            props.setSubmitMessage({
                header: "Answers selected correctly",
                type: "success",
                content: "",
            })
        },
        onError: () => {
            props.setSubmitMessage({
                header: "Error when selecting answers",
                type: "error",
                content: "Try to refresh the page.",
            })
        },
        onSettled: () => {
            props.setButtonEnabled(false);
        }
    });

    const submitAnswers = () => {
        callSaveAnswers(parseUserAnswers(selectedAnswers));
    }

    return (
        <Button
            disabled={!props.buttonEnabled || (selectedAnswers.size < props.numberOfQuestions)}
            iconAlign="left"
            loading={selectAnswersIsLoading}
            variant="normal"
            onClick={submitAnswers}
        >
            Submit
        </Button>
    );
};

export default SelectAnswersButton;
