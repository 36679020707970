import React, {Dispatch, SetStateAction} from "react";
import Button from "@awsui/components-react/button";
import {useMutation} from "react-query";
import {FlashbarProps} from "@awsui/components-react/flashbar/interfaces";
import Box from "@awsui/components-react/box";
import parseRiders from "pages/admin/util/parseRiders";
import Rider from "model/Rider";
import {useAuth} from "hooks/useAuth";
import TeamAndSynonyms from "model/TeamAndSynonyms";
import getTeamSynonyms from "api/getTeamSynonyms";
import Competition from "../../../../../model/Competition";


interface GetTeamSynonymsButtonProps {
    ridersText: string;
    setRidersText: Dispatch<SetStateAction<string>>;
    buttonEnabled: boolean;
    setButtonEnabled: Dispatch<SetStateAction<boolean>>;
    setSubmitMessage: Dispatch<SetStateAction<FlashbarProps.MessageDefinition | undefined>>;
    setTeamSynonyms: Dispatch<SetStateAction<TeamAndSynonyms[] | undefined>>;
    competition: Competition;
}

const GetTeamSynonymsButton: React.FC<GetTeamSynonymsButtonProps> = (props) => {

    const ridersText = props.ridersText;
    const competition = props.competition;

    const [{user}] = useAuth();

    const {
        mutate: getTeamSynonymsMutation,
        isLoading: getTeamSynonymsIsLoading
    } = useMutation((riders: Rider[]) => getTeamSynonyms(riders, competition, user?.token), {
            onSuccess: (riderSynonyms: TeamAndSynonyms[]) => {
                props.setTeamSynonyms(riderSynonyms);
            },
            onError: () => {
                props.setSubmitMessage({
                    header: "Error when getting rider synonyms",
                    type: "error",
                    content: "Try to refresh the page.",
                })
            },
            onSettled: () => {
                props.setButtonEnabled(false);
                props.setRidersText("");
            }
        }
    );

    const submitRiders = () => {
        getTeamSynonymsMutation(parseRiders(ridersText));
    }

    return (
        <Box float="right">
            <Button
                disabled={!props.buttonEnabled || (ridersText === "")}
                iconAlign="left"
                loading={getTeamSynonymsIsLoading}
                variant="primary"
                onClick={submitRiders}
            >
                Submit
            </Button>
        </Box>
    );
};

export default GetTeamSynonymsButton;
